import { FormattedMessage } from 'react-intl'

const IntlMessage = ({
  id,
  values,
}: {
  id: string
  values?: Record<string, any>
}) => <FormattedMessage id={id} values={values} />

export default IntlMessage
