import { useQuery } from '@tanstack/react-query'

import dashboardGroupsApi from '..'

export const GET_SHARED_GROUP_DASHBOARDS = 'Get_Shared_Group_Dashboards'

export const getSharedGroupDashobards = async (id?: string) => {
  if (!id) return null
  return dashboardGroupsApi
    .get(`/shared/${id}`)
    .then(response => response.data?.dashboards || [])
}

const useGetSharedGroupDashboards = (id?: string) =>
  useQuery(
    [GET_SHARED_GROUP_DASHBOARDS, id],
    () => getSharedGroupDashobards(id),
    {
      staleTime: 1000 * 60 * 60,
  })

export default useGetSharedGroupDashboards
