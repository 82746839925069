import React, { useCallback, useEffect, useMemo, useState } from 'react'
import SmartTable from '../SmartTable/SmartTable'
import { useTableContext } from '@mm/ui/src/components/SmartTable/context'
import { useTablesColumns } from '../SmartTable/context/hooks/useColumns'
import useTableData, {
  FETCH_TABLE_DATA,
} from '@mm/api/src/tables/requests/useTableData'
import { BASE_STORAGE_KEY_VIEW } from '@mm/ui/src/components/SmartTable/context/provider'
import {
  combinePredicatesWithOperators,
  createFilterPredicates,
  getCalculatedValue,
  getCampaignObjetiveMetricData,
  MIN_WIDTH_COLUMNS,
} from './utils'
import {
  BaseCardWrapper,
  DateLabel,
  StyledCard,
  StyledCardBody,
  StyledCardTitle,
  StyledTableTitle,
  StyledTableViewApply,
  StyledViewOptions,
  TitleContainer,
} from './style'
import WidgetIcon from '../WidgetIcon'
import { DateChosen } from '../Forms/WidgetForms/widget'
import {
  calculateDateRange,
  WidgetDateRangeProps,
} from '../../helpers/widgetDate'
import { keyBy } from 'lodash'
import TooltipMenu from '../CarouselItem/TooltipMenu'
import { StyledDots } from '../CarouselItem/style'
import { useHistory } from 'react-router-dom'
import useResizeObserver from 'use-resize-observer'
import { sortColumn } from '@mm/ui/src/components/SmartTable/Utils'
import {
  RowData,
  TableReportingColumn,
  TableReportingFilters,
  View,
} from '../SmartTable/types'
import queryClient from '@mm/api/src/reactQuery'
import useUpdateColumnsHook from '@mm/api/src/tables/requests/customHooks/useUpdateColumnsHook'
import useUpdateTableHook from '@mm/api/src/tables/requests/customHooks/useUpdateTableHook'
import useUpdateSingleColumnHook from '@mm/api/src/tables/requests/customHooks/useUpdateSingleColumnHook'
import useUpdateActiveColumnsHook from '@mm/api/src/tables/requests/customHooks/useUpdateActiveColumnsHook'

interface BaseTableProps {
  id: string
  type: 'campaign' | 'client'
  show_icons?: boolean
  dateChosen: DateChosen
  onDelete: () => void
  onDuplicate: () => void
  currentTable: any
  isFetchingData: boolean
  shareable?: string
  pdf?: string
  widgetId?: string
  filters?: TableReportingFilters | string
  columnsView?: TableReportingColumn[]
  view?: View
  dashboard: any
}

export function BaseTable(props: BaseTableProps) {
  const {
    id,
    type,
    dateChosen,
    onDelete,
    onDuplicate,
    currentTable,
    isFetchingData,
    shareable,
    pdf,
    widgetId,
    filters,
    columnsView,
    view,
    dashboard,
  } = props
  const { columns, setColumns, activeColumns } = useTablesColumns()
  const [columnOrder, setColumnOrder] = useState('')
  const [orderedColumn, setOrderedColumn] = useState('')
  const [scrollColumn, setScrollColumn] = useState({ left: 0, top: 0 })
  const [showTooltip, setShowTooltip] = useState(false)
  const history = useHistory()
  const { updateColumnsHandler } = useUpdateColumnsHook(
    currentTable?.id,
    widgetId
  )
  const { updateTableHandler } = useUpdateTableHook(currentTable?.id, widgetId)
  const { updateSingleColumnHandler } = useUpdateSingleColumnHook(
    currentTable?.id,
    widgetId
  )
  const { updateActiveColumnsHandler } = useUpdateActiveColumnsHook(
    currentTable?.id,
    widgetId
  )

  const handleToggleTooltip = () => {
    setShowTooltip(!showTooltip)
  }

  const {
    tableKey,
    refreshTableKey,
    updateCustomFieldData,
    appliedView,
    setChangeTable,
    handleDragEnd,
    lastRefreshTime,
  } = useTableContext()

  useEffect(() => {
    if (currentTable) {
      const orderedColumns = currentTable.columns?.sort(sortColumn('order'))
      setColumns(orderedColumns)
      if (currentTable.additional_config?.sort && !appliedView) {
        setColumnOrder(currentTable.additional_config.sort.order)
        setOrderedColumn(currentTable.additional_config.sort.column)
      }
    }
  }, [currentTable])

  const [stringDate, setStringDate] = useState('')

  useEffect(() => {
    if (currentTable) {
      const widgetDateRangeProps = {
        from: currentTable.date.since,
        to: currentTable.date.until,
        range: currentTable.date.name,
        lastDays: currentTable.date.lastDays,
        untilToday: currentTable.date.untilToday,
        lock: false, // ! TODO: Completar con datos cargados en el widget
      } as WidgetDateRangeProps
      setStringDate(calculateDateRange(dateChosen, widgetDateRangeProps))
    }
  }, [currentTable, dateChosen])

  const filtersData =
    typeof filters === 'string' ? JSON.parse(filters) : filters
  const backFilters = [
    ...(filtersData?.AND ?? []),
    ...(filtersData?.OR ?? []),
  ].filter(filter => filter.property === 'amount_spent')
  const { data, isFetching: isFetchingTableData } = useTableData({
    id,
    filters: backFilters.length ? backFilters : [],
    type,
    lastRefreshTime,
    viewId:
      appliedView?.key ||
      localStorage.getItem(`${BASE_STORAGE_KEY_VIEW}_${id}`),
    dateChosen: dateChosen,
    shareable: shareable,
    pdf: pdf,
    dashboard,
  })

  const clients = useMemo(() => {
    if (!currentTable?.clients) return []
    return currentTable?.clients ?? []
  }, [currentTable?.clients])

  const combinedPredicate = useMemo(() => {
    const predicatesWithOperators = createFilterPredicates(filtersData)
    return combinePredicatesWithOperators(predicatesWithOperators)
  }, [filters])

  const filteredData = useMemo(() => {
    const noData = !data || !Array.isArray(data.values)
    if (noData) return []

    const _filteredData = data.values
    if (columns) {
      for (const col of columns) {
        const isMetric = col.type === 'metric'
        const isFormula = col.type === 'formula'
        const isResult =
          col.type === 'result_formula' || col.type === 'source_result'
        const field = col.data.value
        if (isMetric) continue
        if (isFormula) {
          for (let i = 0; i < _filteredData.length; i++) {
            const row = _filteredData[i]
            const formula = col.data.formula
            const decimals = col.data.decimals

            _filteredData[i][field] = { current: 0, comparedValue: undefined }
            _filteredData[i][field].current = getCalculatedValue({
              row,
              formula,
              columns,
              decimals,
              numberType: col.data.numberType,
              dateChosen: currentTable.date,
            })

            _filteredData[i][field].comparedValue = getCalculatedValue({
              row,
              formula,
              columns,
              decimals,
              numberType: col.data.numberType,
              currentValue: false,
              dateChosen: currentTable.date,
            })
          }
          continue
        }
        if (isResult) {
          for (let i = 0; i < _filteredData.length; i++) {
            const row = _filteredData[i]
            const resultData = getCampaignObjetiveMetricData(row, col)
            if (resultData.filter) {
              resultData.metric = `${resultData.filter.id}_${resultData.metric}`
            }
            _filteredData[i][field] = row[resultData.metric] || '0'
          }
          continue
        }
        if (col.type === 'crm') {
          continue
        }
        const storedData = col.data.stored
        const defaultValue = col.data.default || null
        for (let i = 0; i < _filteredData.length; i++) {
          const row = _filteredData[i]
          const rowId = row.id.current
          const isStored =
            storedData &&
            rowId in storedData &&
            (col.data.type !== 'date' || col.data.dateType === 'manual')
          _filteredData[i][field] = isStored ? storedData[rowId] : defaultValue
        }
      }
    }

    let newFilteredData = _filteredData.filter((col: RowData) =>
      combinedPredicate(col as any)
    )
    if (type === 'client' && clients) {
      const activeClients = clients.map((client: any) => client.id)
      newFilteredData = newFilteredData.filter((rowData: any) =>
        activeClients.includes(rowData.id.current)
      )
    }

    if (orderedColumn && columnOrder && columns) {
      const _sortedColumn = columns.find(
        col => col.data.value === orderedColumn
      )
      if (_sortedColumn) {
        const orderType = _sortedColumn.data.type
        switch (columnOrder) {
          case 'DESC':
            newFilteredData.sort(sortColumn('-' + orderedColumn, orderType))
            break
          case 'ASC':
          default:
            newFilteredData.sort(sortColumn(orderedColumn, orderType))
            break
        }
      }
    }
    if (clients?.length && !orderedColumn) {
      const clientsOrder = keyBy(clients, 'id')
      newFilteredData.sort((prevData: any, nextData: any) => {
        if (!clientsOrder[prevData.id.current]) return 1
        if (!clientsOrder[nextData.id.current]) return -1
        return (
          clientsOrder[prevData.id.current].sort -
          clientsOrder[nextData.id.current].sort
        )
      })
    }
    return newFilteredData

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    columns,
    filters,
    orderedColumn,
    columnOrder,
    clients,
    combinedPredicate,
  ])

  const { ref, height } = useResizeObserver<HTMLDivElement>()

  const smartTableStyle = useMemo(() => {
    const fixedHeight = height ? height - 145 : 200

    return {
      AutoSizer: { height: fixedHeight },
      StickyGrid: { height: fixedHeight },
    } as { [key: string]: React.CSSProperties }
  }, [height])

  const sourcesUpper = useMemo(() => {
    return (currentTable?.sources ?? []).map((source: string) =>
      source?.toUpperCase()
    )
  }, [currentTable])

  const updateColumnPosition = useCallback(
    (scroll: { left: number; top: number }) => {
      setScrollColumn(scroll)
    },
    []
  )
  const onEdit = useCallback(async () => {
    if (!currentTable) return

    await queryClient.invalidateQueries([FETCH_TABLE_DATA, id])

    history.push(`/app/overview/${currentTable.group_by}/${currentTable.id}`)
  }, [currentTable, history])

  const handleColumnOrder = (value: string) => {
    let _order = null
    if (columnOrder === 'ASC') {
      setOrderedColumn('')
      delete currentTable.additional_config.sort
    } else {
      _order = columnOrder === 'DESC' ? 'ASC' : 'DESC'
      setOrderedColumn(value)
    }
    setColumnOrder(_order ?? '')
    updateTableHandler({
      additional_config: {
        ...currentTable.additional_config,
        ...(_order ? { sort: { order: _order, column: value } } : {}),
      },
    })
  }

  const handleColumnCompare = (
    col: string,
    compare: {
      enabled: boolean
      period: string
      from: Date | null
      to: Date | null
    }
  ) => {
    const column = activeColumns.find(c => c.data.value === col)
    if (!column) return

    updateSingleColumnHandler({
      id: id,
      columnId: column.id,
      data: { ...column.data, compare },
    })
  }

  function handleHideActiveColumn(col: string) {
    const column = currentTable.columns.find((c: any) => c.data.value === col)
    const selections = activeColumns
      .filter(c => c.data.value !== col)
      .map(c => {
        return {
          label: c.label,
          value: c.data.value,
          type: c.data.type,
          source: c.data.source,
        }
      })

    const type = column.type === 'metric' ? 'metric' : 'custom'

    updateActiveColumnsHandler({
      id,
      selections,
      type,
    })
    setChangeTable(true)
  }

  async function updateTableData(
    newColumns: Array<TableReportingColumn>,
    property: string
  ) {
    let updatedColumns: {
      id: number
      order?: number
      width?: number
      hidden?: boolean
    }[]
    switch (property) {
      case 'order':
        updatedColumns = newColumns.map((column, index) => {
          return { ...column, order: index }
        })
        break
      case 'hidden':
        updatedColumns = newColumns.map(column => {
          return { ...column, hidden: column.hidden }
        })
        break
      case 'width':
        updatedColumns = newColumns.map(column => {
          return { ...column, width: column.width }
        })
        break
      case 'active':
        updatedColumns = newColumns.map(column => {
          return { ...column, active: column.active }
        })
        break
      default:
        updatedColumns = newColumns
        break
    }

    if (updatedColumns) {
      await updateColumnsHandler(updatedColumns, property)
    }
  }

  function handleColumnResize(column: string, width = MIN_WIDTH_COLUMNS) {
    width = width >= MIN_WIDTH_COLUMNS ? width : MIN_WIDTH_COLUMNS
    const columnsAux = columns
    const colIndex = columns.findIndex(col => col.data?.value === column)
    columnsAux[colIndex].width = width
    setColumns(columnsAux)
    updateTableData(columnsAux, 'width')
    refreshTableKey()
  }

  if (isFetchingData || isFetchingTableData || !filteredData || !currentTable) {
    return (
      <BaseCardWrapper ref={ref} className="draggable-handle">
        <StyledCard id={`powertable-${id}`}>
          <StyledCardBody
            className="scrollbar-color"
            style={{ overflow: 'hidden', position: 'static' }}
          >
            <div className="loading"></div>
          </StyledCardBody>
        </StyledCard>
      </BaseCardWrapper>
    )
  }

  const fixedColumnsNames = ['campaign_name', 'client_name']
  return (
    <BaseCardWrapper ref={ref} className="draggable-handle">
      <StyledCard id={`overview-${widgetId}`}>
        <WidgetIcon sources={sourcesUpper} />

        <StyledCardBody
          className="scrollbar-color"
          style={{ overflow: 'hidden', position: 'static' }}
        >
          <>
            <TooltipMenu
              show={showTooltip}
              toggle={handleToggleTooltip}
              onDelete={onDelete}
              onEdit={onEdit}
              onDuplicate={onDuplicate}
            />

            <StyledViewOptions
              onClick={handleToggleTooltip}
              className="view-options"
              onMouseDown={e => e.stopPropagation()}
              onMouseMove={e => e.stopPropagation()}
            >
              <StyledDots className="dots"></StyledDots>
            </StyledViewOptions>
            <StyledCardTitle>
              <TitleContainer>
                <StyledTableTitle>{currentTable.name}</StyledTableTitle>
                {view ? (
                  <StyledTableViewApply>({view.name})</StyledTableViewApply>
                ) : null}
                <DateLabel>{stringDate}</DateLabel>
              </TitleContainer>
            </StyledCardTitle>

            <SmartTable
              key={tableKey}
              disableEditColumn={true}
              disableRemoveColumn={true}
              modalContained={true}
              appliedView={appliedView}
              data={filteredData}
              smartTableStyle={smartTableStyle}
              activeColumns={columnsView ?? activeColumns}
              columns={columns}
              condtional_format={currentTable?.conditional_format}
              columnOrder={columnOrder}
              orderedColumn={orderedColumn}
              onOrderChange={handleColumnOrder}
              onCompareChange={handleColumnCompare}
              onColumnsResize={handleColumnResize}
              updateCustomFieldData={updateCustomFieldData}
              onChangeScroll={updateColumnPosition}
              scrollPosition={scrollColumn}
              onHideColumn={handleHideActiveColumn}
              table={currentTable}
              tableMetrics={{}}
              type={type}
              handleDragEnd={handleDragEnd}
              EmptyState={undefined}
              fixedColumnsNames={fixedColumnsNames}
              allowEdit={false}
              isPublic={!!shareable || !!pdf}
            />
          </>
        </StyledCardBody>
      </StyledCard>
    </BaseCardWrapper>
  )
}
