import enMessages from './locales/en_US'
import esMessages from './locales/es_ES'
import ptMessages from './locales/pt_PT'

const AppLocale = {
  en: enMessages,
  es: esMessages,
  pt: ptMessages,
}

export default AppLocale
