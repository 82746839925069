import React from 'react'

const SvgComponent = ({ width = '14', height = '14' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
    >
      <g clipPath="url(#clip0_16_598)">
        <mask
          id="mask0_16_598"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="112"
          height="30"
        >
          <path d="M112 0H0V30H112V0Z" fill="white"></path>
        </mask>
        <g mask="url(#mask0_16_598)">
          <path
            d="M16 23.5054C7.03 23.5054 0 19.7704 0 15.0054C0 10.2404 7.03 6.50537 16 6.50537C24.97 6.50537 32 10.2404 32 15.0054C32 19.7704 24.97 23.5054 16 23.5054ZM16 9.00537C9.615 9.00537 2.5 11.4704 2.5 15.0054C2.5 18.5404 9.615 21.0054 16 21.0054C22.385 21.0054 29.5 18.5404 29.5 15.0054C29.5 11.4704 22.385 9.00537 16 9.00537Z"
            fill="#4EC375"
          ></path>
          <path
            d="M21.4152 29.525C17.4052 29.525 12.2752 25.56 8.63521 19.255C6.57021 15.68 5.31521 11.905 5.09521 8.62001C4.85521 5.02001 5.88521 2.37001 7.99521 1.15001C10.1052 -0.0699896 12.9202 0.36501 15.9152 2.37001C18.6502 4.20501 21.2902 7.18001 23.3552 10.755C27.8402 18.525 28.1202 26.48 23.9952 28.86C23.2152 29.31 22.3452 29.525 21.4102 29.525H21.4152ZM10.6252 2.97501C10.1052 2.97501 9.64021 3.08501 9.25021 3.31501C8.02521 4.02001 7.42021 5.89501 7.59021 8.45501C7.78521 11.365 8.92521 14.76 10.8002 18.01C13.9902 23.54 19.6852 28.47 22.7452 26.7C25.8052 24.93 24.3852 17.54 21.1902 12.01C19.3152 8.76001 16.9452 6.07501 14.5252 4.45501C13.0752 3.48501 11.7252 2.98501 10.6202 2.98501L10.6252 2.97501Z"
            fill="#1463FF"
          ></path>
          <path
            d="M10.6198 29.5402C9.6648 29.5402 8.7848 29.3152 7.9998 28.8602C5.8898 27.6402 4.85481 24.9902 5.0998 21.3902C5.3198 18.1052 6.57481 14.3302 8.63981 10.7552C13.1248 2.9852 19.8748 -1.2348 23.9998 1.1452C28.1248 3.5302 27.8448 11.4802 23.3598 19.2502C21.2948 22.8252 18.6548 25.8002 15.9198 27.6352C14.0398 28.8952 12.2298 29.5352 10.6198 29.5352V29.5402ZM21.3098 2.9452C18.1298 2.9452 13.5448 7.2502 10.7998 12.0002C8.9248 15.2502 7.7848 18.6402 7.5898 21.5552C7.4198 24.1152 8.0248 25.9852 9.2498 26.6952C10.4748 27.4002 12.3948 26.9902 14.5298 25.5602C16.9548 23.9352 19.3198 21.2502 21.1948 18.0052C24.3848 12.4752 25.8098 5.0802 22.7498 3.3152C22.3198 3.0652 21.8348 2.9502 21.3098 2.9502V2.9452Z"
            fill="#FFBC00"
          ></path>
          <path
            d="M16 21.0054C9.615 21.0054 2.5 18.5404 2.5 15.0054H0C0 19.7704 7.03 23.5054 16 23.5054V21.0054Z"
            fill="#4EC375"
          ></path>
          <path
            d="M16.5748 6.07979C15.9048 5.45979 15.2198 4.90979 14.5298 4.44479C13.8498 3.98979 13.1898 3.63479 12.5698 3.38979L13.3998 1.02979C14.2198 1.34479 15.0648 1.78979 15.9248 2.36979C16.8298 2.97979 17.7298 3.70979 18.5998 4.54979L16.5798 6.07979H16.5748Z"
            fill="#1463FF"
          ></path>
          <path
            d="M18.5801 26.2454C17.5001 25.6654 16.3751 24.8004 15.2851 23.7554L13.3701 25.4204C14.4901 26.5054 15.6401 27.3954 16.7801 28.0654L18.5851 26.2454H18.5801Z"
            fill="#1463FF"
          ></path>
          <path
            d="M27.52 11.945C24.96 10.125 20.315 9 16 9V6.5C21.025 6.5 25.44 7.67 28.355 9.54L27.52 11.945Z"
            fill="#4EC375"
          ></path>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_16_598">
          <rect width="112" height="30" fill="white"></rect>
        </clipPath>
      </defs>
    </svg>
  )
}
export default SvgComponent
