import React from 'react'
import SmartTableActions from './SmartTableActions'
import orderAscendingIcon from '../../assets/icons/order-ascending.svg'
import orderDescendingIcon from '../../assets/icons/order-descending.svg'
import {
  TableHeader,
  TableCell,
  ColumnTitle,
  OrderedColumnTitle,
  OrderedColumnIcon,
  TableActions,
  ResizedBorder,
} from './Styles'
import { ColData } from './types'
import { getFactor } from './ComparedValueMarker/mapCompareMetricFactor'

const comparableTypes = ['formula', 'result_formula', 'source_result', 'crm']
const notComaprableValues = ['client_name', 'campaign_name']
const isComparableColumn = (column: ColData) => {
  return (
    (column.type === 'metric' &&
      !!getFactor(column.data.value) &&
      !notComaprableValues.includes(column?.data?.value)) ||
    comparableTypes.includes(column.type)
  )
}

interface SmartTableHeaderProps {
  style: React.CSSProperties
  column: ColData
  columnIndex: number
  isFirstColumn: boolean
  isLastColumn: boolean
  orderedColumn: string
  columnOrder: string
  handleHideColumn: (col: string) => void
  onEditColumn?: (col: ColData) => void
  onEditMetricColumn?: (col: ColData) => void
  saveScroll: () => void
  handleCompareColumn: (
    col: string,
    compare: {
      enabled: boolean
      period: string
      from: Date | null
      to: Date | null
    }
  ) => void
  handleOrderColumn: (value: string) => void
  onColumnsResize?: (label: string, width?: number) => void
  handleDeleteColumn: (col: string) => void
  initial: (e: any, column: string) => void
  resize: (e: any, column: string) => void
  maxWidth: number
  minWidth: number
  isFixed: boolean
  isFixeadble: boolean
  overviewType: 'campaign' | 'client'
  disableEditColumn?: boolean
  disableRemoveColumn?: boolean
}

export const SmartTableHeader = React.memo((props: SmartTableHeaderProps) => {
  const {
    style,
    column,
    columnIndex,
    isFirstColumn,
    isLastColumn,
    orderedColumn,
    columnOrder,
    handleHideColumn,
    onEditColumn,
    onEditMetricColumn,
    saveScroll,
    handleCompareColumn,
    handleOrderColumn,
    onColumnsResize,
    handleDeleteColumn,
    initial,
    resize,
    maxWidth,
    minWidth,
    isFixed,
    isFixeadble,
    overviewType,
    disableEditColumn,
    disableRemoveColumn,
  } = props

  const { width, id, hidden, label, type } = column
  const { value, alias } = column.data

  const isOrdered = value === orderedColumn
  const onCompareColumn = (
    col: string,
    compare: {
      enabled: boolean
      period: string
      from: Date | null
      to: Date | null
    }
  ) => {
    handleCompareColumn(col, compare)
  }

  const editColumn = disableEditColumn
    ? undefined
    : () => {
        saveScroll()
        if (column.type === 'metric') {
          onEditMetricColumn && onEditMetricColumn(column)
        } else {
          onEditColumn && onEditColumn(column)
        }
      }

  const onDeleteColumn = disableRemoveColumn ? undefined : handleDeleteColumn
  return (
    <TableHeader style={style}>
      <TableCell
        id={`${value}-col`}
        key={id}
        hidden={hidden}
        style={{
          borderTopLeftRadius: isFirstColumn ? 8 : 0,
          borderTopRightRadius: isLastColumn ? 8 : 0,
          width: maxWidth - 8,
        }}
      >
        <ColumnTitle id={`${value}-title`}>
          <OrderedColumnTitle>{alias ? alias : label}</OrderedColumnTitle>
          {isOrdered && (
            <OrderedColumnIcon
              alt=""
              src={
                columnOrder === 'ASC' ? orderAscendingIcon : orderDescendingIcon
              }
              title={`Order is ${columnOrder?.toLowerCase()}ending`}
            />
          )}
        </ColumnTitle>
        <TableActions id={`${value}-actions`}>
          <SmartTableActions
            col={value}
            colType={type}
            isCompared={column.data?.compare?.enabled}
            onCompareColumn={onCompareColumn}
            onHideColumn={handleHideColumn}
            isFixed={isFixed}
            isFixeadble={isFixeadble}
            onEditColumn={editColumn}
            onOrderColumn={(orderValue: string) => {
              handleOrderColumn(orderValue)
              if (width <= minWidth + 42) {
                onColumnsResize && onColumnsResize(orderValue, width + 24)
              }
            }}
            onDeleteColumn={onDeleteColumn}
            isOrdered={isOrdered}
            order={columnOrder}
            columnIndex={columnIndex}
            isComparable={isComparableColumn(column)}
            overviewType={overviewType}
          />
        </TableActions>
      </TableCell>
      <ResizedBorder
        draggable="true"
        onMouseDown={e => initial(e, value)}
        onDragEnd={e => resize(e, value)}
        title={'Double click to auto adjust'}
        className="resized-border-column"
      >
        <div />
        <div />
      </ResizedBorder>
    </TableHeader>
  )
})
