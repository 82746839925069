import styled from 'styled-components'

export const ReloadSourceWrapper = styled.div`
  width: 20px;
  heigth: 20px;
  img {
    width: 100%;
  }
  cursor: pointer;
`

export const MessageText = styled.div`
  color: #fff;
  font-size: 12px;
`
