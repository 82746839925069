import React from 'react'
import { IconsColors } from '../../../components/IconComponent/IconComponent'

const SvgComponent = ({ variable, width = '14', height = '14' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3465 3.41394C13.0957 3.41394 10.9492 4.29341 9.35421 5.84366C8.53436 5.50081 7.65489 5.32194 6.74561 5.32194C3.07868 5.32194 0.0825195 8.3181 0.0825195 11.9999C0.0825195 15.6818 3.07868 18.6779 6.76052 18.6779C7.65489 18.6779 8.53436 18.4991 9.35421 18.1562C10.9045 19.6618 13.0062 20.5859 15.3316 20.5859C20.0718 20.5859 23.9176 16.7401 23.9176 11.9999C23.9325 7.25975 20.0867 3.41394 15.3465 3.41394ZM15.3465 18.6779C11.6647 18.6779 8.66852 15.6818 8.66852 11.9999H6.76052C6.76052 13.6843 7.25243 15.2495 8.08718 16.5762C7.65489 16.6954 7.22261 16.7699 6.77543 16.7699C4.13702 16.7699 2.00543 14.6234 2.00543 11.9999C2.00543 9.36153 4.15193 7.22994 6.77543 7.22994C7.81886 7.22994 8.80268 7.55788 9.63743 8.18394C10.8448 9.09322 11.5454 10.4944 11.5454 11.9999H13.4534C13.4534 10.0025 12.5889 8.16903 11.0684 6.88709C12.2609 5.88838 13.7665 5.32194 15.3465 5.32194C19.0284 5.32194 22.0245 8.3181 22.0245 11.9999C22.0245 15.6818 19.0284 18.6779 15.3465 18.6779Z"
      fill={variable ? IconsColors[variable] : '#0272D5'}
    />
  </svg>
)

export default SvgComponent
