import React from 'react'
import { BackgroundIconsColors } from '../../components/IconComponent/IconComponent'

const SvgComponent = ({ variable }) => {
  return (
    <>
      <svg width="23" height="23" viewBox="0 0 23 23" fill="none">
        <rect
          width="23"
          height="23"
          rx="3"
          fill={BackgroundIconsColors[variable].background}
        />
        <path
          d="M11.8 7L11.8 11.8M11.8 11.8L11.8 16.6M11.8 11.8H16.6M11.8 11.8L7 11.8"
          stroke={BackgroundIconsColors[variable].color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </>
  )
}
export default SvgComponent
