import styled from 'styled-components'
// Contenedor principal del widget
export const WidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
  text-align: center;
  color: #b1aeae;
  font-family: Arial, sans-serif;
`

// Texto de mensaje de error
export const MessageText = styled.div<{
  size: 'mini' | 'small' | 'medium' | 'large'
}>`
  color: #b1aeae;
  font-size: ${({ size }) => {
    switch (size) {
      case 'mini':
        return '10px'
      case 'small':
        return '12px'
      case 'medium':
        return '15px'
      case 'large':
        return '18px'
      default:
        return '16px'
    }
  }};
  line-height: ${({ size }) => {
    switch (size) {
      case 'mini':
        return '10px'
      case 'small':
        return '13px'
      case 'medium':
        return '16px'
      case 'large':
        return '19px'
      default:
        return '17px'
    }
  }};
`

// Botón de reconexión
export const ReconnectButton = styled.button<{
  size: 'mini' | 'small' | 'medium' | 'large'
}>`
  display: flex;
  align-items: center;
  padding: ${({ size }) => {
    switch (size) {
      case 'mini':
        return '3px 6px'
      case 'small':
        return '5px 10px'
      case 'medium':
        return '7px 14px'
      case 'large':
        return '10px 20px'
      default:
        return '10px 20px'
    }
  }};
  margin-top: ${({ size }) => {
    switch (size) {
      case 'mini':
        return '3px'
      case 'small':
        return '10px'
      case 'medium':
        return '12px'
      case 'large':
        return '15px'
      default:
        return '20px'
    }
  }};
  font-size: ${({ size }) => {
    switch (size) {
      case 'mini':
        return '9px'
      case 'small':
        return '12px'
      case 'medium':
        return '15px'
      case 'large':
        return '18px'
      default:
        return '16px'
    }
  }};
  font-weight: bold;
  color: #fff;
  background-color: #4552e8;
  border: 2px solid #4267b2;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    background-color: #365899;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }

  &:active {
    background-color: #2a4480;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  svg {
    margin-right: 8px;
  }
`

export const SvgIcon = styled.svg`
  fill: #ccc;
`
