import React, { useRef, useState } from 'react'
import DotsIcon from '../../assets/svgIcons/DotsIcon'
import EditBackgroundIcon from '../../assets/svgIcons/EditBackgroundIcon'
import EditIcon from '../../assets/svgIcons/EditIcon'
import ExportBackgroundIcon from '../../assets/svgIcons/ExportBackgroundIcon'
import ExportIcon from '../../assets/svgIcons/ExportIcon'
import LinkBackgroundIcon from '../../assets/svgIcons/LinkBackgroundIcon'
import LinkIcon from '../../assets/svgIcons/LinkIcon'
import PlusBackgroundIcon from '../../assets/svgIcons/PlusBackgroundIcon'
import PlusIcon from '../../assets/svgIcons/PlusIcon'
import DataSource from '../../assets/svgIcons/DataSourceIcon'
import TrashIcon from '../../assets/svgIcons/TrashIcon'
import TrashBackgroundIcon from '../../assets/svgIcons/TrashBackgroundIcon'
import MetaIcon from '../../assets/svgIcons/datasources/MetaIcon'
import GoogleAdsIcon from '../../assets/svgIcons/datasources/GoogleAdsIcon'
import GoogleAnalyticsIcon from '../../assets/svgIcons/datasources/GoogleAnalyticsIcon'
import TiktokIcon from '../../assets/svgIcons/datasources/TiktokIcon'
import LinkedinIcon from '../../assets/svgIcons/datasources/LinkedinIcon'
import InstagramIcon from '../../assets/svgIcons/datasources/InstagramIcon'
import TiendaNubeIcon from '../../assets/svgIcons/datasources/TiendaNubeIcon'
import CloseIcon from '../../assets/svgIcons/datasources/CloseIcon'
import HubspotIcon from '../../assets/svgIcons/datasources/HubspotIcon'
import RefreshIcon from '../../assets/svgIcons/RefreshIcon'
import ChevronLeftIcon from '../../assets/svgIcons/ChevronLeftIcon'
import ChevronRightIcon from '../../assets/svgIcons/ChevronRightIcon'
import WarningIcon from '../../assets/svgIcons/WarningIcon'
import ShowPasswordIcon from '../../assets/svgIcons/ShowPasswordIcon'
import HidePasswordIcon from '../../assets/svgIcons/HidePasswordIcon'
import MasterMetricsIcon from '../../assets/svgIcons/MasterMetricsIcon'
import { CustomTooltip } from './styles'

export enum IconVariable {
  Primary,
  Secondary,
  Black,
  Cancel,
}
export const IconsColors = {
  [IconVariable.Primary]: '#646EEB',
  [IconVariable.Secondary]: '#FFFFFF',
  [IconVariable.Black]: '#000000',
  [IconVariable.Cancel]: '#E74848',
}

export const BackgroundIconsColors = {
  [IconVariable.Primary]: { background: '#646EEB', color: '#FFFFFF' },
  [IconVariable.Secondary]: { background: '#FFFFFF', color: '#000000' },
  [IconVariable.Cancel]: { background: '#E74848', color: '#FFFFFF' },
}
export enum IconNames {
  Plus,
  PlusBackground,
  Edit,
  EditBackground,
  Export,
  ExportBackground,
  Link,
  LinkBackground,
  Dots,
  DataSource,
  TrashIcon,
  TrashBackgroundIcon,
  MetaIcon,
  GoogleAdsIcon,
  GoogleAnalyticsIcon,
  TiktokIcon,
  LinkedinIcon,
  InstagramIcon,
  TiendaNubeIcon,
  CloseIcon,
  HubspotIcon,
  RefreshIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  WarningIcon,
  ShowPasswordIcon,
  HidePasswordIcon,
  MasterMetricsIcon,
}

interface SVGAttributes {
  icon: IconNames
  variable: IconVariable
  text?: string
  width?: string
  height?: string
}

const IconComponent = ({
  icon,
  variable,
  text,
  width,
  height,
}: SVGAttributes) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const iconRef = useRef(null)

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen)
  }

  const AvailableIcons = {
    [IconNames.Plus]: (
      <PlusIcon variable={variable} width={width} height={height} />
    ),
    [IconNames.PlusBackground]: <PlusBackgroundIcon variable={variable} />,
    [IconNames.Edit]: <EditIcon variable={variable} />,
    [IconNames.EditBackground]: <EditBackgroundIcon variable={variable} />,
    [IconNames.Export]: <ExportIcon variable={variable} />,
    [IconNames.ExportBackground]: <ExportBackgroundIcon variable={variable} />,
    [IconNames.Link]: <LinkIcon variable={variable} />,
    [IconNames.LinkBackground]: <LinkBackgroundIcon variable={variable} />,
    [IconNames.Dots]: <DotsIcon variable={variable} />,
    [IconNames.DataSource]: <DataSource variable={variable} />,
    [IconNames.TrashIcon]: (
      <TrashIcon variable={variable} width={width} height={height} />
    ),
    [IconNames.TrashBackgroundIcon]: (
      <TrashBackgroundIcon variable={variable} />
    ),
    [IconNames.MetaIcon]: <MetaIcon width={width} height={height} />,
    [IconNames.GoogleAdsIcon]: <GoogleAdsIcon width={width} height={height} />,
    [IconNames.GoogleAnalyticsIcon]: (
      <GoogleAnalyticsIcon width={width} height={height} />
    ),
    [IconNames.TiktokIcon]: <TiktokIcon width={width} height={height} />,
    [IconNames.LinkedinIcon]: <LinkedinIcon width={width} height={height} />,
    [IconNames.InstagramIcon]: <InstagramIcon width={width} height={height} />,
    [IconNames.TiendaNubeIcon]: (
      <TiendaNubeIcon variable={variable} width={width} height={height} />
    ),
    [IconNames.CloseIcon]: <CloseIcon width={width} height={height} />,
    [IconNames.HubspotIcon]: <HubspotIcon width={width} height={height} />,
    [IconNames.RefreshIcon]: <RefreshIcon variable={variable} />,
    [IconNames.ChevronLeftIcon]: (
      <ChevronLeftIcon variable={variable} width={width} height={height} />
    ),
    [IconNames.ChevronRightIcon]: (
      <ChevronRightIcon variable={variable} width={width} height={height} />
    ),
    [IconNames.WarningIcon]: (
      <WarningIcon variable={variable} width={width} height={height} />
    ),
    [IconNames.ShowPasswordIcon]: (
      <ShowPasswordIcon variable={variable} width={width} height={height} />
    ),
    [IconNames.HidePasswordIcon]: (
      <HidePasswordIcon variable={variable} width={width} height={height} />
    ),
    [IconNames.MasterMetricsIcon]: (
      <MasterMetricsIcon variable={variable} width={width} height={height} />
    ),
  }
  return (
    <>
      <div ref={iconRef} style={{ display: 'flex', alignItems: 'center' }}>
        {AvailableIcons[icon]}
      </div>
      {iconRef.current && text && (
        <CustomTooltip
          isOpen={tooltipOpen}
          target={iconRef.current}
          toggle={toggleTooltip}
          delay={{ show: 5, hide: 200 }}
        >
          {text}
        </CustomTooltip>
      )}
    </>
  )
}

export default IconComponent
