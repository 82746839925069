import { useQuery } from '@tanstack/react-query'
import powertablesApi from '../'

export const GET_POWERTABLE_DATA_KEY = 'Powertables_GetPowertableData'

export const getPowertableData = async (
  id: string,
  shareable?: string,
  pdf?: string,
  dashboard?: any
) => {
  let queryParams
  if (shareable) {
    queryParams = `?shareable=${shareable}&dashboard=${dashboard.id}`
  } else if (pdf) {
    queryParams = `?pdf=${pdf}&dashboard=${dashboard.id}`
  }
  return powertablesApi
    .get(`/${id}/data${queryParams ? queryParams : ''}`)
    .then(response => response.data)
}

const useGetPowertableData = (
  id: string,
  shareable?: string,
  pdf?: string,
  dashboard?: any
) =>
  useQuery(
    [GET_POWERTABLE_DATA_KEY, id],
    () => getPowertableData(id, shareable, pdf, dashboard),
    {
      staleTime: 1000 * 60 * 60, // 1 hour
      refetchOnWindowFocus: false,
      retry: false,
    }
  )

export default useGetPowertableData
