import React from 'react'
import { ErrorWrapper, WarningIconWrapper } from './styles'
import WarningIcon from '../../assets/icons/warning.svg'

export const ErrorMessage = ({ message }: { message: string }) => {
  return (
    <>
      <ErrorWrapper>
        <div>
          <WarningIconWrapper>
            <img className="warning-icon" alt="" src={WarningIcon} />
          </WarningIconWrapper>
          <p>{message}</p>
        </div>
      </ErrorWrapper>
    </>
  )
}
