import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Popover,
  PopoverBody,
  FormGroup,
  PopoverHeader,
} from 'reactstrap'
import Select from 'react-select'
import useGetGroupDashboards from '@mm/api/src/dashboardGroups/requests/useGetGroupDashboards'
import { Dashboard } from '../SmartTable/types'

interface CopyMoveMenuProps {
  dashboards: any[]
  duplicateId: string
  dashboardIdDefault: number
  targetId: string
  type: string
  groupId: string
  onDuplicateMove: (
    data: null | {
      duplicateId: string
      dashboardId: number
      targetId: string
      type: string
    }
  ) => void
  onClose: () => void
  open: boolean
}

const CopyMoveMenu: React.FC<CopyMoveMenuProps> = (
  props: CopyMoveMenuProps
) => {
  const popoverOpen = props.open
  const [dashboardId, setDashboardId] = useState<string>(props.groupId)

  const [selectSection, setSelectSection] = useState<null | Dashboard>(null)

  const changeSelectSection = (e: any) => {
    setSelectSection(e)
  }

  const selectDashboard = useMemo(() => {
    const selectedDashboard = props.dashboards.find(d => d.id === dashboardId)
    if (!selectedDashboard) return null

    return {
      id: selectedDashboard.id,
      label: selectedDashboard.name,
      value: selectedDashboard.id,
      sections: selectedDashboard.dashboards,
    }
  }, [props.dashboards, dashboardId])

  const {
    data: groupSections,
    isLoading,
    error,
  } = useGetGroupDashboards(selectDashboard?.id, {
    enabled: !!selectDashboard?.id,
  })

  const initalSection = () => {
    if (groupSections) {
      const section = groupSections.find(
        das => das.id === props.dashboardIdDefault
      )
      if (section) {
        section.label = section.name
        setSelectSection(section)
      } else {
        setSelectSection(null)
      }
    } else {
      setSelectSection(null)
    }
  }

  useEffect(() => {
    initalSection()
  }, [selectDashboard.id])

  if (!props.open || !props.targetId || !props.type || !props.dashboards) {
    return <></>
  }

  const handleOkClick = () => {
    if (!selectDashboard?.id) return

    props.onDuplicateMove({
      duplicateId: props.duplicateId,
      groupId: selectDashboard?.id,
      dashboardId: selectSection?.id,
      targetId: props.targetId,
      type: props.type,
    })
  }

  const target = document.getElementById(props.targetId)
  if (!target) {
    return <></>
  }

  return (
    <Popover
      placement="bottom"
      trigger="legacy"
      isOpen={popoverOpen}
      target={props.targetId}
      toggle={props.onClose}
      style={{ padding: 20, width: 250 }}
    >
      <PopoverHeader>Copy widget to </PopoverHeader>
      <PopoverBody>
        <FormGroup>
          <Select
            name="Select Dashboard"
            options={props.dashboards.map(dashboard => {
              return {
                id: dashboard.id,
                label: dashboard.name,
                value: dashboard.id,
              }
            })}
            value={selectDashboard}
            onChange={(selected: any) => {
              setDashboardId(selected.value)
            }}
            placeholder="Select Dashboard"
            className="react-select"
            classNamePrefix="react-select"
          />
          {selectDashboard && groupSections?.length && (
            <div style={{ marginTop: '15px' }}>
              <Select
                name="Select Section"
                options={groupSections.map((section: Dashboard) => {
                  return {
                    id: section.id,
                    label: section.name,
                    value: section.id,
                  }
                })}
                value={selectSection}
                onChange={e => changeSelectSection(e)}
                placeholder="Choose a Section"
                className="react-select"
                classNamePrefix="react-select"
              />
            </div>
          )}
        </FormGroup>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            style={{
              width: '75px',
              marginRight: '5px',
            }}
            color="danger"
            onClick={props.onClose}
            size="sm"
          >
            Cancel
          </Button>
          <Button
            style={{ width: '75px' }}
            size="sm"
            onClick={handleOkClick}
            disabled={!selectSection}
          >
            Copy
          </Button>
        </div>
      </PopoverBody>
    </Popover>
  )
}

export default CopyMoveMenu
