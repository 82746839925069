import { useQuery } from '@tanstack/react-query'

import dashboardApi from '..'

export const GET_SHARED_DASHBOARD = 'Get_Shared_Dashboard'

export const getShaerdDashboard = async (id: number, uuid: string) => {
  return dashboardApi
    .get(`/shared/data/${id}?uuid=${uuid}`)
    .then(response => response.data)
}

const useGetSharedDashboard = (id: number, uuid: string, queryOptions: any) =>
  useQuery([GET_SHARED_DASHBOARD, id], () => getShaerdDashboard(id, uuid), {
    staleTime: 1000 * 60 * 60,
    ...(queryOptions || {}),
  })

export default useGetSharedDashboard
