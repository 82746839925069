import React, { useState, useEffect, useMemo } from 'react'

import useGetSimpletableData from '@mm/api/src/simpletables/requests/useGetSimpletableData'
import { capitalizeString } from '@mm/utils/src/helpers/Utils'
import { Table } from 'reactstrap'
import Loader from '../Loader'
import { ErrorMessage } from '../Powertable/style'
import {
  StyledResponsiveTable,
  StyledTh,
  ErrorTr,
  StyledCard,
  StyledCardBody,
  StyledCardTitle,
  StyledTableTitle,
  StyledViewOptions,
  StyledDots,
  TableData,
  DateLabel,
} from './style'
import Tooltip from './Tooltip'
import WidgetIcon from '../WidgetIcon'
import { DashboardDate, calculateDateRange } from '../../helpers/widgetDate'
import { formatMetric } from '../Powertable/utils'
import WidgetErrorMessage from '../WidgetErrorMessage/WidgetErrorMessage'

const SimpleTable = ({
  id,
  shareable,
  edited,
  dateChosen,
  pdf,
  onDelete,
  onDuplicate,
  onEdit,
  dashboard,
}: {
  id: string
  shareable?: string
  edited?: number
  dateChosen?: DashboardDate
  pdf?: string
  dashboard?: any
  onDelete: () => void
  onDuplicate: () => void
  onEdit: () => void
}) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [dateFormat, setDateFormat] = useState('')

  const {
    data: response,
    error,
    isFetching,
  }: { data: any; error: any; isFetching: boolean } = useGetSimpletableData({
    id,
    shareable,
    pdf,
    dashboard,
  })

  //@ts-ignore
  const hideActions = import.meta.env.VITE_APP_IS_PUBLIC === 'false'

  useEffect(() => {
    if (response) {
      setDateFormat(calculateDateRange(dateChosen!, response))
    }
  }, [response, dateChosen])

  const firstError = useMemo(() => {
    if (!response) return undefined

    const errorsData = response?.data?.errorsData ?? []

    return errorsData.find((error: any) => error.message)
  }, [response])

  return (
    <StyledCard id={`simpletable-${id}`}>
      <StyledCardBody>
        {isFetching ? (
          <Loader style={{ margin: 'auto' }} />
        ) : error ? (
          <ErrorMessage>
            {`Error loading table: ${
              error.response?.data?.message ||
              'Server could not process table data'
            }`}
          </ErrorMessage>
        ) : (
          <>
            {response.data.show_icons && (
              <WidgetIcon sources={response.data.source} />
            )}
            <StyledCardTitle>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {firstError ? null : (
                  <>
                    <StyledTableTitle>{response.name}</StyledTableTitle>
                    <DateLabel style={{ marginLeft: '15px' }}>
                      {dateFormat}
                    </DateLabel>
                  </>
                )}
              </div>
              {hideActions && (
                <>
                  <StyledViewOptions
                    onClick={() => setShowTooltip(!showTooltip)}
                    className="view-options"
                    onMouseDown={e => e.stopPropagation()}
                    onMouseMove={e => e.stopPropagation()}
                  >
                    <StyledDots className="dots"></StyledDots>
                  </StyledViewOptions>

                  <Tooltip
                    show={showTooltip}
                    toggle={() => setShowTooltip(!showTooltip)}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    onDuplicate={onDuplicate}
                    loading={isFetching}
                  />
                </>
              )}
            </StyledCardTitle>
            {firstError ? (
              <WidgetErrorMessage
                error={firstError}
                containerStyle={{ height: 'calc(100% - 48px)' }}
                widgetId={id ?? ''}
                isPublic={!!shareable || !!pdf}
                onEdit={dateChosen?.dateFilterRange ? undefined : onEdit}
              />
            ) : (
              <StyledResponsiveTable className="scrollbar-color">
                <Table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      {response.data.rows && response.data.rows.length ? (
                        response.data.headings.map(
                          (heading: string, index: number) => (
                            <StyledTh key={index} style={{ minWidth: '80px' }}>
                              {heading}
                            </StyledTh>
                          )
                        )
                      ) : (
                        <th>Metrics</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {response.data.rows && response.data.rows.length
                      ? response.data.rows.map(
                          (
                            row: {
                              source: string
                              value: string
                              type: string
                              warning: string
                              error: string
                            }[],
                            i: number
                          ) => {
                            return (
                              <tr key={i}>
                                {row &&
                                  row.map((cell, ii: number) => {
                                    if (ii) {
                                      const value = cell.type
                                        ? formatMetric(
                                            cell.type,
                                            cell.value || 0
                                          )
                                        : 0
                                      return (
                                        <TableData
                                          key={ii}
                                          title={
                                            cell.warning || cell.error || ''
                                          }
                                          hoverMessage={
                                            cell.warning || cell.error
                                          }
                                          warning={cell.warning}
                                        >
                                          {value}
                                        </TableData>
                                      )
                                    } else {
                                      return (
                                        <th
                                          key={ii}
                                          scope="row"
                                          style={{
                                            fontWeight: '400',
                                            textAlign: 'left',
                                          }}
                                        >
                                          {capitalizeString(cell)}
                                        </th>
                                      )
                                    }
                                  })}
                              </tr>
                            )
                          }
                        )
                      : !response.data.errors &&
                        !response.data.errors.length && (
                          <tr>
                            <th>
                              There are no metrics selected for this table!
                            </th>
                          </tr>
                        )}
                    {response.data.errors && response.data.errors.length
                      ? response.data.errors.map((error: String, i: number) => {
                          return (
                            <ErrorTr key={i}>
                              <div>{error}</div>
                            </ErrorTr>
                          )
                        })
                      : null}
                  </tbody>
                </Table>
              </StyledResponsiveTable>
            )}
          </>
        )}
      </StyledCardBody>
    </StyledCard>
  )
}

export default SimpleTable
