// @ts-nocheck
import React, { Component } from 'react'
import Loader from '../Loader'
import { StyledCard } from './style'

import PowerTile from './PowerTile'
import { Tile } from './Tile'

class CarouselItem extends Component<any> {
  state = {
    showTooltip: false,
    hideActions: import.meta.env.VITE_APP_IS_PUBLIC === 'false',
  }

  handleToggleTooltip = () => {
    this.setState({ showTooltip: !this.state.showTooltip })
  }

  render() {
    const {
      metrics,
      data,
      onDuplicate,
      onCompare,
      onEdit,
      onDelete,
      loading,
      isLoading,
      id,
      type,
      widget,
      shareable,
      dateChosen,
      pdf,
      dashboard,
    } = this.props

    const { showTooltip, hideActions } = this.state

    return (
      <div
        className="card-container"
        style={{ width: '100%', height: 'auto', display: 'flex' }}
        id={type === 'powerTile' ? `powertile-${id}` : `tile-${id}`}
      >
        <StyledCard>
          {loading ? (
            <div style={{ margin: '54px' }}>
              <Loader />
            </div>
          ) : (
            <>
              {type === 'tile' ? (
                <Tile
                  hideActions={hideActions}
                  isLoading={isLoading}
                  showTooltip={showTooltip}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  onDuplicate={onDuplicate}
                  handleToggleTooltip={this.handleToggleTooltip}
                  data={data}
                  metrics={metrics}
                  shareable={shareable}
                  pdf={pdf}
                  widget={widget}
                  dateChosen={dateChosen}
                />
              ) : (
                <PowerTile
                  dateChosen={dateChosen}
                  hideActions={hideActions}
                  isLoading={isLoading}
                  showTooltip={showTooltip}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  onDuplicate={onDuplicate}
                  onCompare={onCompare}
                  handleToggleTooltip={this.handleToggleTooltip}
                  shareable={shareable}
                  widget={widget}
                  pdf={pdf}
                  dashboard={dashboard}
                />
              )}
            </>
          )}
        </StyledCard>
      </div>
    )
  }
}

export default CarouselItem
