import React from 'react'
import { IconsColors } from '../../components/IconComponent/IconComponent'

const SvgComponent = ({ variable, width = '14', height = '14' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 1L1 5.00065L5 9"
        stroke={IconsColors[variable]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default SvgComponent
