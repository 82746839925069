import React from 'react'
import { BackgroundIconsColors } from '../../components/IconComponent/IconComponent'

const SvgComponent = ({ variable }) => {
  return (
    <>
      <svg width="23" height="23" viewBox="0 0 23 23" fill="none">
        <rect
          width="23"
          height="23"
          rx="3"
          fill={BackgroundIconsColors[variable].background}
        />
        <path
          d="M11.0041 6.99203H7.87654C6.84016 6.99203 6 7.83216 6 8.86851V15.1235C6 16.1599 6.84016 17 7.87654 17H14.1317C15.1681 17 16.0082 16.1599 16.0082 15.1235L16.0082 11.996M9.12757 13.8725L11.4034 13.4139C11.5242 13.3896 11.6351 13.3301 11.7223 13.2429L16.8169 8.14564C17.0612 7.90125 17.061 7.50511 16.8165 7.26093L15.7373 6.18295C15.4929 5.93887 15.097 5.93904 14.8528 6.18332L9.75765 11.2811C9.6707 11.3681 9.61133 11.4788 9.58696 11.5994L9.12757 13.8725Z"
          stroke={BackgroundIconsColors[variable].color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
}
export default SvgComponent
