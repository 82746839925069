import React from 'react'
import { IconsColors } from '../../components/IconComponent/IconComponent'

const SvgComponent = ({ variable }) => {
  return (
    <>
      <svg width="10" height="11" viewBox="0 0 10 11" fill="none">
        <path
          d="M1 9.69136C1.20235 9.88898 1.4768 10 1.76297 10H8.23703C8.5232 10 8.79765 9.88898 9 9.69136M5.0006 1V6.97123M5.0006 6.97123L7.46691 4.68965M5.0006 6.97123L2.5343 4.68965"
          stroke={IconsColors[variable]}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
}
export default SvgComponent
