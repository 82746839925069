import { useQuery } from '@tanstack/react-query'
import dashboards from '..'

export const USE_DASHBOARD_EXPORT_PDF = 'Dashboard_Export_PDF'

export const dashboardExportPdf = async (
  uuid: string,
  publicLink?: boolean,
  dashboardId?: string
) => {
  let endpoint = `/generate-pdf/${dashboardId}`
  if (publicLink) {
    endpoint = `/share/generate-pdf/${uuid}`
  }
  return dashboards
    .get(endpoint, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.data)
}

const useDashboardExportPdf = (
  uuid: string,
  publicLink?: boolean,
  dashboardId?: string
) =>
  useQuery(
    [USE_DASHBOARD_EXPORT_PDF],
    () => dashboardExportPdf(uuid, publicLink, dashboardId),
    {
      staleTime: 1000 * 60 * 60, // 1 hour
      refetchOnWindowFocus: false,
      retry: false,
    }
  )

export default useDashboardExportPdf
