import { useQuery } from '@tanstack/react-query'
import simpletableApi from '../index'

export const GET_SIMPLETABLE_DATA_KEY = 'Simpletables_GetTableData'

export const getSimpleTableData = async (
  id: string,
  shareable?: string,
  pdf?: string,
  dashboard?: any
) => {
  let queryParams
  if (shareable) {
    queryParams = `?shareable=${shareable}&dashboard=${dashboard.id}`
  } else if (pdf) {
    queryParams = `?pdf=${pdf}&dashboard=${dashboard.id}`
  }

  return simpletableApi
    .get(`/${id}/data${queryParams ? queryParams : ''}`)
    .then(response => response.data)
}

const useGetSimpletableData = ({
  id,
  shareable,
  pdf,
  dashboard,
}: {
  id: string
  shareable?: string
  pdf?: string
  dashboard?: any
}) =>
  useQuery(
    [GET_SIMPLETABLE_DATA_KEY, id],
    () => getSimpleTableData(id, shareable, pdf, dashboard),
    {
      staleTime: 1000 * 60 * 60, // 1 hour
      refetchOnWindowFocus: false,
      retry: false,
    }
  )

export default useGetSimpletableData
