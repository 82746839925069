import styled from 'styled-components'
import { Tooltip } from 'reactstrap'

export const CustomTooltip = styled(Tooltip)`
  .tooltip-inner {
    background-color: #fffffff7 !important;
    border: 1px solid #fafafa !important;
    color: #000000 !important;
    font-size: 10px !important;
    border-radius: 3px !important;
    box-shadow: 1px 1px 8.7px -3px #00000014 !important;
  }

  .arrow::before {
    display: none !important;
  }
`
