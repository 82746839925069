import React from 'react'
import { IconsColors } from '../../components/IconComponent/IconComponent'

const SvgComponent = ({ variable, width = '12', height = '12' }) => {
  return (
    <>
      <svg width={width} height={height} viewBox="0 0 12 12" fill="none">
        <path
          d="M5.8 1L5.8 5.8M5.8 5.8L5.8 10.6M5.8 5.8H10.6M5.8 5.8L1 5.8"
          stroke={IconsColors[variable]}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </>
  )
}
export default SvgComponent
