import React from 'react'
import { IconsColors } from '../../components/IconComponent/IconComponent'

const SvgComponent = ({ variable }) => {
  return (
    <svg width="22" height="24" viewBox="0 0 14 14" fill="none">
      <path
        d="M3.4 7C3.4 7.66274 2.86274 8.2 2.2 8.2C1.53726 8.2 1 7.66274 1 7C1 6.33726 1.53726 5.8 2.2 5.8C2.86274 5.8 3.4 6.33726 3.4 7ZM3.4 7H10.6M10.6 7C10.6 7.66274 11.1373 8.2 11.8 8.2C12.4627 8.2 13 7.66274 13 7C13 6.33726 12.4627 5.8 11.8 5.8C11.1373 5.8 10.6 6.33726 10.6 7ZM10.6 2.2C10.6 2.86274 11.1373 3.4 11.8 3.4C12.4627 3.4 13 2.86274 13 2.2C13 1.53726 12.4627 1 11.8 1C11.1373 1 10.6 1.53726 10.6 2.2ZM10.6 2.2H7.15C6.81863 2.2 6.55 2.46863 6.55 2.8V11.2C6.55 11.5314 6.81863 11.8 7.15 11.8H10.6M10.6 11.8C10.6 12.4627 11.1373 13 11.8 13C12.4627 13 13 12.4627 13 11.8C13 11.1373 12.4627 10.6 11.8 10.6C11.1373 10.6 10.6 11.1373 10.6 11.8Z"
        stroke={IconsColors[variable]}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
export default SvgComponent
