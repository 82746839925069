import React from 'react'

export const EditIconSVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.1667 8.49993C20.3855 8.28106 20.6454 8.10744 20.9313 7.98899C21.2173 7.87054 21.5238 7.80957 21.8333 7.80957C22.1429 7.80957 22.4494 7.87054 22.7353 7.98899C23.0213 8.10744 23.2811 8.28106 23.5 8.49993C23.7189 8.7188 23.8925 8.97863 24.0109 9.2646C24.1294 9.55057 24.1904 9.85706 24.1904 10.1666C24.1904 10.4761 24.1294 10.7826 24.0109 11.0686C23.8925 11.3546 23.7189 11.6144 23.5 11.8333L12.25 23.0833L7.66667 24.3333L8.91667 19.7499L20.1667 8.49993Z"
      stroke="#fff"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
