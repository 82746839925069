import useMultiAxisChartData from '@mm/api/src/multiaxischarts/requests/useGetMultiAxisChartData'
import React, { useMemo } from 'react'
import ChartContainer from '../ChartContainer'
import MultiChart from './MultiChart'
import { DashboardDate } from '../../../helpers/widgetDate'
import WidgetErrorMessage from '../../WidgetErrorMessage/WidgetErrorMessage'

interface ChartProps {
  onDuplicate: (id: string) => void
  onEdit: (id: string) => void
  onDelete: (id: string) => void
  id: string
  chart: any
  shareable?: string
  dateChosen: DashboardDate
  pdf?: string
  dashboard?: any
}

const MultiAxischart = ({
  chart,
  shareable,
  onDelete,
  onDuplicate,
  onEdit,
  id,
  dateChosen,
  pdf,
  dashboard,
}: ChartProps) => {
  const {
    data: response,
    isFetching,
    error,
  }: { data: any; isFetching: boolean; error: any } = useMultiAxisChartData({
    id: id,
    shareable: shareable,
    pdf,
    dashboard,
  })

  const firstError = useMemo(() => {
    if (!response) return undefined

    const errorsData = response?.data?.errorsData ?? []

    return errorsData.find((error: any) => error.message)
  }, [response])

  return (
    <>
      <ChartContainer
        id={`multiaxischart-${id}`}
        loading={isFetching}
        name={response?.name}
        onDelete={onDelete}
        onDuplicate={onDuplicate}
        onEdit={onEdit}
        sources={response?.data.source}
        show_icons={response?.show_icons && !firstError}
        dateChosen={dateChosen}
        response={response}
        shareable={shareable}
        hideTitle={!!firstError}
      >
        {firstError ? (
          <WidgetErrorMessage
            error={firstError}
            containerStyle={{ height: 'calc(100% - 45px)' }}
            widgetId={chart.id}
            isPublic={!!shareable || !!pdf}
            onEdit={dateChosen?.dateFilterRange ? undefined : onEdit}
          />
        ) : (
          <MultiChart id={chart.id} response={response?.data} error={error} />
        )}
      </ChartContainer>
    </>
  )
}

export default MultiAxischart
