import React, { useEffect, useState } from 'react'
import {
  DashboardSelectionWrapper,
  DashboardOption,
  AddDashboardWrapper,
  IconWrapper,
  OptionText,
} from './styles'

import IconComponent from '../IconComponent/IconComponent'
import { IconNames, IconVariable } from '../IconComponent/IconComponent'
import Tooltip from './Tooltip'

interface Dashboard {
  id: string
  name: string
  layout?: any
  dashboardGroupId?: string
  createdAt?: string
  updatedAt?: string
  deletedAt?: string | null
  userId?: number
}

interface DashboardSelectionTypes {
  changeActiveSection: (dashboard: Dashboard | null) => void
  toggleDashboardTemplateModal?: () => void
  handleDashboardTitleBlur?: (
    activeDashboardId: string,
    dashboards: Dashboard
  ) => Promise<any>
  onDelete?: () => void
  onDuplicate?: () => void
  dashboards: Dashboard[]
  activeDashboard: Dashboard
}

const DashboardSelection = ({
  dashboards,
  activeDashboard,
  changeActiveSection,
  toggleDashboardTemplateModal,
  handleDashboardTitleBlur,
  onDelete,
  onDuplicate,
}: DashboardSelectionTypes) => {
  const [currentActiveDashboard, setCurrentActiveDashboard] =
    useState<Dashboard | null>(null)
  const [showTooltip, setShowTooltip] = useState(false)
  const [editTitle, setEditTitle] = useState(false)

  const clickDashboard = (dash: Dashboard) => {
    changeActiveSection(dash)
  }

  const toogle = () => {
    setShowTooltip(!showTooltip)
  }

  const onEdit = () => {
    setEditTitle(true)
  }

  const onTitleChange = (e: any) => {
    if (currentActiveDashboard) {
      const oldName = { ...currentActiveDashboard }
      oldName.name = e.target.value
      setCurrentActiveDashboard(oldName)
    }
  }

  const onTitleblur = async () => {
    await handleDashboardTitleBlur(activeDashboard.id, currentActiveDashboard!)
    setEditTitle(false)
  }

  useEffect(() => {
    if (activeDashboard) setCurrentActiveDashboard({ ...activeDashboard })
  }, [activeDashboard])

  return (
    <DashboardSelectionWrapper className="scrollbar-color scrollbar-vertical-color">
      {dashboards && dashboards.length
        ? dashboards.map(dash => (
            <DashboardOption
              active={dash?.id === activeDashboard?.id}
              key={dash.id}
              onClick={() => clickDashboard(dash)}
            >
              {editTitle && dash.id === activeDashboard?.id ? (
                <input
                  type="text"
                  style={{ display: 'flex', width: '100%' }}
                  value={currentActiveDashboard?.name || ''}
                  onChange={e => {
                    onTitleChange(e)
                  }}
                  onBlur={onTitleblur}
                />
              ) : (
                <OptionText>{dash.name}</OptionText>
              )}

              {onDelete && (
                <IconWrapper
                  onClick={toogle}
                  style={{
                    display: dash?.id === activeDashboard?.id ? '' : 'none',
                  }}
                >
                  <IconComponent
                    icon={IconNames.Dots}
                    variable={IconVariable.Black}
                  />
                </IconWrapper>
              )}
              <div
                style={{
                  position: 'relative',
                  top: '-36px',
                  right: '-58px',
                }}
              >
                <Tooltip
                  show={showTooltip && dash?.id === activeDashboard?.id}
                  toggle={toogle}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  onDuplicate={onDuplicate}
                />
              </div>
            </DashboardOption>
          ))
        : null}

      {toggleDashboardTemplateModal && (
        <AddDashboardWrapper>
          <div
            onClick={() => toggleDashboardTemplateModal()}
            style={{ cursor: 'pointer' }}
          >
            <IconComponent
              icon={IconNames.Plus}
              variable={IconVariable.Black}
            />
          </div>
        </AddDashboardWrapper>
      )}
    </DashboardSelectionWrapper>
  )
}

export default DashboardSelection
