import axios, { AxiosRequestHeaders } from 'axios'
import * as constants from '../_constants'
import { auth } from '@mm/utils/src/helpers/Utils'
import { apiInstance, handleJWTError } from '@mm/api/src'
const instance = apiInstance('dashboard', true, true)

instance.interceptors.request.use(config => {
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      ...(auth().headers || {}),
    } as AxiosRequestHeaders,
  }
})

handleJWTError(instance)

export default instance
