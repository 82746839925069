// @ts-nocheck
import { apiInstance } from '@mm/api'

export const publicLinkApiInstance = apiInstance('', true, true)

export async function uptadeShareable(id, dateFilters) {
  await publicLinkApiInstance.post(`shareables/${id}?shareable=${id}`, {
    dateFilters,
  })
}
