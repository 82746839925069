import { useQuery } from '@tanstack/react-query'

import dashboardGroupsApi from '..'

export const GET_GROUP_DASHBOARDS = 'Get_Group_Dashboards'

export const getGroupDashobards = async (id?: string) => {
  if (!id) return null
  return dashboardGroupsApi
    .get(`/${id}`)
    .then(response => response.data?.dashboards || [])
}

const useGetGroupDashboards = (id: string, queryOptions: any) =>
  useQuery([GET_GROUP_DASHBOARDS, id], () => getGroupDashobards(id), {
    staleTime: 1000 * 60 * 60,
    ...queryOptions,
  })

export default useGetGroupDashboards
