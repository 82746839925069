import useGetPowerchartData from '@mm/api/src/powercharts/requests/useGetPowerchartData'
import React, { useEffect, useMemo, useState } from 'react'
import Chart from './Chart'
import ChartContainer from './ChartContainer'
import WidgetErrorMessage from '../WidgetErrorMessage/WidgetErrorMessage'

interface PowerChartProps {
  onDuplicate: (id: string) => void
  onEdit: (id: string) => void
  onDelete: (id: string) => void
  shareable?: string
  pdf?: string
  dashboard?: any
  chart: any
  loading: boolean
  dateChosen: any
}

const setChartType = (chartType, stacked) => {
  if (chartType === 'line' && stacked) {
    return 'area'
  } else {
    return chartType
  }
}

const setChartStacking = (chartType, stacked) => {
  if (chartType === 'line' && stacked) {
    return undefined
  } else if (stacked) {
    return 'normal'
  } else {
    return undefined
  }
}

const PowerChart = ({
  chart,
  shareable,
  onDelete,
  onEdit,
  onDuplicate,
  dateChosen,
  loading,
  pdf,
  dashboard,
}: PowerChartProps) => {
  const [options, setOptions] = useState<Highcharts.Options | {}>({})
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [counter, setCounter] = useState(0)

  const {
    data: response,
    isFetching: isLoading,
    error,
  }: { data: any; isFetching: boolean; error: any } = useGetPowerchartData({
    id: chart.id,
    shareable: shareable,
    pdf: pdf,
    dashboard,
  })

  const parseData = () => {
    if (!response) return {}
    const optionsData: Highcharts.Options = {
      legend: {
        itemDistance: 5,
        margin: 7,
        itemWidth: 200,
        maxHeight: 70,
      },
      title: {
        text: '',
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: setChartType(response.chartType, response.stacked),
        width: null, // Dynamically adjust width
        height: null, // Dynamically adjust height
      },
      series: [
        //data Rows
      ],
      xAxis: {
        //Headers
        categories: [],
        crosshair: true,
      },
      yAxis: {
        //Metrics
        title: {
          text: '',
        },
      },
      plotOptions: {
        series: {
          stacking: setChartStacking(response.chartType, response.stacked),
        },
      },
      responsive: {},
    }

    if (response.chartType === 'pie') {
      const newData: { name: string; data: any[] } = {
        name: 'Total',
        data: [],
      }
      for (const d of response.data.rows) {
        //@ts-ignore
        const data = {
          name: d[0],
          y: Number(d[1]),
        }
        newData.data.push(data)
      }
      //@ts-ignore
      optionsData.series = [{ ...newData }]
      optionsData.plotOptions!.pie = {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      }
    } else if (
      response.chartType === 'bar' ||
      response.chartType === 'line' ||
      response.chartType === 'column'
    ) {
      response.data.headings.forEach((header, i) => {
        if (i === 0) return
        //@ts-ignore
        optionsData.xAxis?.categories.push(header)
      })

      for (const d of response.data.rows) {
        const newData = {
          name: d[0],
          type: '',
          data: d.slice(1, d.length).map(str => Number(str)),
        }

        //@ts-ignore
        optionsData.series?.push(newData)
      }
    }

    setOptions(optionsData)
  }

  useEffect(() => {
    parseData()
    setCounter(prev => prev + 1)
  }, [response])

  useEffect(() => {
    if (error?.response?.data.message) {
      setErrorMessage(error.response.data.message)
    } else {
      setErrorMessage('')
    }
  }, [error])

  const errorData = useMemo(() => {
    if (error?.response?.data) return error?.response?.data

    return undefined
  }, [error])

  return (
    <ChartContainer
      id={`powerchart-${chart.id}`}
      loading={isLoading || loading}
      name={response?.name}
      onDelete={onDelete}
      onDuplicate={onDuplicate}
      onEdit={onEdit}
      sources={response?.source}
      show_icons={response?.show_icons && !errorData}
      dateChosen={dateChosen}
      response={response}
      shareable={shareable}
    >
      {errorData?.message ? (
        <WidgetErrorMessage
          error={error.response.data}
          containerStyle={{ height: 'calc(100% - 45px)' }}
          widgetId={chart.id}
          isPublic={!!shareable || !!pdf}
          onEdit={dateChosen?.dateFilterRange ? undefined : onEdit}
        />
      ) : (
        <Chart options={options} error={errorMessage} chartId={chart.id} />
      )}
    </ChartContainer>
  )
}

export default PowerChart
