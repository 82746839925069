import React, { useState } from 'react'
import { MessageText, ReloadSourceWrapper } from './styles'
import { Tooltip } from 'reactstrap'
import { ReconnectIconSvg } from '../../WidgetErrorMessage/ReconnectIconSVG'
import { IntlMessages } from '@mm/utils'
import { SourceError } from '../SmartTableErrorCell'
import { additionHandlers } from '@mm/api/src/tokenError.middleware'

const ReloadSourceAlert = ({
  error,
  id,
}: {
  error: SourceError
  id: string
}) => {
  const [toggle, setToggle] = useState(false)

  if (error.code === 'ACCESS_TOKEN_HAS_EXPIRED') {
    const reconnect =
      additionHandlers[(error?.errorData?.source ?? 'DATASOURCE').toUpperCase()]
    return (
      <>
        <ReloadSourceWrapper id={`tooltip-${id}`} onClick={reconnect}>
          <ReconnectIconSvg stroke="#4552e8"></ReconnectIconSvg>
        </ReloadSourceWrapper>
        <Tooltip
          placement={'right'}
          isOpen={toggle}
          target={`tooltip-${id}`}
          toggle={() => setToggle(!toggle)}
        >
          <MessageText>
            <IntlMessages
              id="token-revalidate-error.message"
              values={{
                source: error?.errorData?.sourceName,
              }}
            />
          </MessageText>
        </Tooltip>
      </>
    )
  }

  const reconnect = additionHandlers['DATASOURCE'.toUpperCase()]
  return (
    <>
      <ReloadSourceWrapper
        id={`tooltip-${id}`}
        onClick={() => reconnect({ source: error?.errorData?.source })}
      >
        <ReconnectIconSvg stroke="#4552e8"></ReconnectIconSvg>
      </ReloadSourceWrapper>
      <Tooltip
        placement={'right'}
        isOpen={toggle}
        target={`tooltip-${id}`}
        toggle={() => setToggle(!toggle)}
      >
        <MessageText>
          <IntlMessages
            id="token-revalidate-missing-permissions.message"
            values={{
              account: error?.errorData?.adAccount?.name,
              source: error?.errorData?.sourceName,
            }}
          />
        </MessageText>
      </Tooltip>
    </>
  )
}

export default ReloadSourceAlert
