import { useQuery } from '@tanstack/react-query'

import tablesApi from '../'
import { DateChosen } from '@mm/ui/src/components/Forms/WidgetForms/widget'

export const FETCH_TABLE_DATA = 'Fetch_Tables_Data'

const fetchTableData = async (params: {
  id: string
  filters: object
  lastRefreshTime: number
  viewId?: string
  dateChosen?: DateChosen
  shareable?: string
  pdf?: string
  dashboard?: any
}) => {
  const {
    id,
    filters,
    lastRefreshTime,
    viewId,
    dateChosen,
    shareable,
    pdf,
    dashboard,
  } = params
  const refreshParam =
    new Date().getTime() < lastRefreshTime ? '&refresh=true' : ''
  const viewParam = viewId ? `&viewId=${viewId}` : ''
  const urlSearchParams = new URLSearchParams()
  let dateChosenParams = ''
  if (dateChosen) {
    Object.entries(dateChosen).map(([key, value]) => {
      value && urlSearchParams.append(key, String(value))
    })
    dateChosenParams = dateChosen ? `&${urlSearchParams}` : ''
  }

  if (shareable || pdf) {
    const param = shareable ? `shareable=${shareable}` : `pdf=${pdf}`

    return (
      await tablesApi.get(
        `/shareable/${id}/data?filter=${JSON.stringify(
          filters
        )}${refreshParam}${viewParam}${dateChosenParams}&${param}&dashboard=${dashboard.id}`
      )
    ).data
  }

  return (
    await tablesApi.get(
      `/${id}/data?filter=${JSON.stringify(
        filters
      )}${refreshParam}${viewParam}${dateChosenParams}`
    )
  ).data
}

export function useTableData(
  params: {
    id: string
    filters: any[]
    lastRefreshTime: number
    type: 'campaign' | 'client'
    viewId?: string
    dateChosen?: DateChosen
    shareable?: string
    pdf?: string
    dashboard?: any
  },
  queryOptions: any = {}
) {
  const {
    id,
    filters,
    lastRefreshTime,
    type,
    viewId,
    dateChosen,
    shareable,
    pdf,
    dashboard,
  } = params
  const stringFilters = filters
    .map(
      filter =>
        `${filter.property}-${filter.value}-${filter.filter}-${filter.operator || 'AND'}`
    )
    .join('-')
  const stringDateChosen = dateChosen ? JSON.stringify(dateChosen) : ''
  return useQuery(
    [
      FETCH_TABLE_DATA,
      id,
      stringFilters,
      lastRefreshTime.toString(),
      type,
      viewId,
      stringDateChosen,
      shareable,
      pdf,
      dashboard,
    ],
    () => fetchTableData(params),
    {
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 60,
      retry: false,
      ...queryOptions,
    }
  )
}

export default useTableData
