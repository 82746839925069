import axios from 'axios'
import * as constants from '../../../../apps/reporting/src/api/_constants'
import { auth } from '@mm/utils/src/helpers/Utils'
import { handleJWTError } from '@mm/api/src'

const instance = axios.create({
  baseURL: `${constants.BASE_URL}dashboardgroups`,
  timeout: constants.TIMEOUT * 10,
  headers: constants.headers,
})

//@ts-ignore
instance.interceptors.request.use(config => ({
  ...config,
  headers: {
    ...(config.headers || {}),
    ...(auth().headers || {}),
  },
}))

handleJWTError(instance)

export default instance
