import React from 'react'
import { IconsColors } from '../../components/IconComponent/IconComponent'

const SvgComponent = ({ variable }) => {
  return (
    <>
      <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
        <path
          d="M6.00411 1.99203H2.87654C1.84016 1.99203 1 2.83216 1 3.86851V10.1235C1 11.1599 1.84016 12 2.87654 12H9.13169C10.1681 12 11.0082 11.1599 11.0082 10.1235L11.0082 6.99601M4.12757 8.87247L6.40339 8.41392C6.52421 8.38958 6.63514 8.33009 6.72227 8.24292L11.8169 3.14564C12.0612 2.90125 12.061 2.50511 11.8165 2.26093L10.7373 1.18295C10.4929 0.938871 10.097 0.939037 9.85283 1.18332L4.75765 6.28113C4.6707 6.36813 4.61133 6.47883 4.58696 6.5994L4.12757 8.87247Z"
          stroke={IconsColors[variable]}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
}
export default SvgComponent
