import { useQuery } from '@tanstack/react-query'
import powertileApi from '../index'

export const GET_POWERTILE_DATA_KEY = 'Powertiles_GetPowertileData'

interface DataChosen {
  dateFilterFrom?: any
  dateFilterRange: string
  dateFilterTo?: any
  dateFilterLastDays: number
  dateFilterUntilToday: boolean
}

export const getPowerTileData = async (
  id: string,
  shareable?: string,
  dateChosen?: DataChosen,
  pdf?: string,
  dashboard?: any
) => {
  let queryParams
  if (shareable) {
    queryParams = `?shareable=${shareable}&dashboard=${dashboard.id}`
  } else if (pdf) {
    queryParams = `?pdf=${pdf}&dashboard=${dashboard.id}`
  }
  return powertileApi
    .get(`/${id}/data${queryParams ? queryParams : ''}`)
    .then(response => response.data)
}

const useGetPowertileData = ({
  id,
  shareable,
  dateChosen,
  pdf,
  dashboard,
}: {
  id: string
  shareable?: string
  dateChosen?: DataChosen
  pdf?: string
  dashboard?: any
}) =>
  useQuery(
    [GET_POWERTILE_DATA_KEY, id, JSON.stringify(dateChosen)],
    () => getPowerTileData(id, shareable, dateChosen, pdf, dashboard),
    {
      staleTime: 1000 * 60 * 60, // 1 hour
      refetchOnWindowFocus: false,
      retry: false,
    }
  )

export default useGetPowertileData
