import React, { useEffect, useMemo, useState } from 'react'
import Chart from './Chart'
import ChartContainer from './ChartContainer'
import { tableIconSource } from '../WidgetIcon/utils'
import WidgetErrorMessage from '../WidgetErrorMessage/WidgetErrorMessage'

const parseError = (chart): string | undefined => {
  const rows = chart?.data?.rows

  if (rows) {
    for (const row of rows) {
      for (const cell of row) {
        if (cell.error) return cell.error
      }
    }
  }

  return undefined
}

const fomatValues = value => {
  if (typeof value === 'string')
    return Number(value.replace(/[^\d.,]/g, '').replace(',', '.'))
  return value
}

const QueryChart = ({
  chart,
  onDelete,
  onEdit,
  onDuplicate,
  dateChosen,
  shareable,
  pdf,
}) => {
  const [options, setOptions] = useState<Highcharts.Options | {}>({})

  const [sources] = useState(tableIconSource(chart.metrics))

  const parseData = () => {
    if (!chart.data) return {}

    const optionsData: Highcharts.Options = {
      legend: {
        itemDistance: 5,
        margin: 7,
        itemWidth: 200,
        maxHeight: 70,
      },
      title: {
        text: '',
      },
      chart: {
        type: chart.chartType,
        width: null, // Dynamically adjust width
        height: null, // Dynamically adjust height
      },
      series: [
        //data Rows
      ],
      xAxis: {
        //Headers
        categories: [],
        crosshair: true,
      },
      yAxis: {
        //Metrics
        title: {
          text: '',
        },
      },
      plotOptions: {},
      responsive: {},
    }

    if (chart.chartType === 'pie') {
      const newData: { name: string; data: any[] } = {
        name: 'Total',
        data: [],
      }
      for (const d of chart.data.rows) {
        let totalValue = 0

        d.forEach((row, i) => {
          if (i === 0) return
          totalValue = totalValue + fomatValues(row.value)
        })

        //@ts-ignore
        const data = {
          name: d[0],
          y: totalValue,
        }
        newData.data.push(data)
      }
      //@ts-ignore
      optionsData.series = newData
      //@ts-ignore
      optionsData.plotOptions.pie = {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      }
    } else if (
      chart.chartType === 'bar' ||
      chart.chartType === 'line' ||
      chart.chartType === 'column'
    ) {
      chart.data.headings.forEach((header, i) => {
        if (i === 0) return
        //@ts-ignore
        optionsData.xAxis?.categories.push(header)
      })

      for (const d of chart.data.rows) {
        const newData = {
          name: d[0],
          type: '',
          data: d.slice(1, d.length).map(value => {
            return fomatValues(value.value)
          }),
        }

        //@ts-ignore
        optionsData?.series?.push(newData)
      }
    }
    setOptions(optionsData)
  }

  useEffect(() => {
    parseData()
  }, [chart])
  const error = useMemo(() => {
    return parseError(chart)
  }, [chart])

  const errorData = useMemo(() => {
    if (!chart) return undefined
    const rows = chart?.data?.rows ?? []

    return (
      rows.reduce((acc, row) => {
        if (!row || !Array.isArray(row)) {
          return acc
        }
        const error = row?.find(item => item?.error)?.error
        if (error) {
          acc.push(error)
        }
        return acc
      }, [])[0] ??
      (chart?.data?.errorsData ?? []).find(e => typeof e === 'object')
    )
  }, [chart])

  return (
    <ChartContainer
      id={`chart-${chart.id}`}
      loading={false}
      name={chart.name}
      hideTitle={!!errorData}
      onDelete={onDelete}
      onDuplicate={onDuplicate}
      onEdit={onEdit}
      sources={sources}
      show_icons={chart.dashboard_widgets.show_icons && !errorData}
      response={chart}
      shareable={shareable}
      dateChosen={dateChosen}
    >
      {errorData ? (
        <WidgetErrorMessage
          error={errorData}
          containerStyle={{ height: 'calc(100% - 45px)' }}
          widgetId={chart.id}
          isPublic={!!shareable || !!pdf}
          onEdit={dateChosen?.dateFilterRange ? undefined : onEdit}
        />
      ) : (
        <Chart options={options} error={error} chartId={chart.id} />
      )}
    </ChartContainer>
  )
}

export default QueryChart
