import styled from 'styled-components'

export const ErrorWrapper = styled.div`
  min-height: 50vh;
  display: grid;
  justify-content: center;
  align-items: center;
  color: #fc6363;
  p {
    font-size: 16px;
  }
`

export const WarningIconWrapper = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  img {
    filter: invert(28%) sepia(57%) saturate(3671%) hue-rotate(321deg)
      brightness(116%) contrast(122%);
    height: 50px;
  }
`
