import React from 'react'
import { IconsColors } from '../../components/IconComponent/IconComponent'

const SvgComponent = ({ variable, width = '14', height = '14' }) => {
  return (
    <>
      <svg width={width} height={height} viewBox="0 0 13 14" fill="none">
        <path
          d="M1 3.11765H12M5.125 10.1765V5.94118M7.875 10.1765V5.94118M9.25 13H3.75C2.99061 13 2.375 12.3679 2.375 11.5882V3.82353C2.375 3.43368 2.6828 3.11765 3.0625 3.11765H9.9375C10.3172 3.11765 10.625 3.43368 10.625 3.82353V11.5882C10.625 12.3679 10.0094 13 9.25 13ZM5.125 3.11765H7.875C8.2547 3.11765 8.5625 2.80161 8.5625 2.41176V1.70588C8.5625 1.31603 8.2547 1 7.875 1H5.125C4.7453 1 4.4375 1.31603 4.4375 1.70588V2.41176C4.4375 2.80161 4.7453 3.11765 5.125 3.11765Z"
          stroke={IconsColors[variable]}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
}
export default SvgComponent
