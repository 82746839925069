import React from 'react'
import { SvgIcon } from './style'

export const AccountPermissionErrorSVG: React.FC = () => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    style={{ opacity: 0.3 }}
  >
    {/* Sombra del monitor */}
    <ellipse cx="32" cy="56" rx="18" ry="2" fill="rgba(0, 0, 0, 0.1)" />
    {/* Monitor estilizado */}
    <rect
      x="8"
      y="8"
      width="48"
      height="36"
      rx="4"
      ry="4"
      fill="#f1f3f4"
      stroke="#bbb"
      strokeWidth="2"
    />
    {/* Base del monitor */}
    <rect x="24" y="46" width="16" height="2" fill="#ccc" />
    <rect x="22" y="48" width="20" height="2" fill="#ccc" />

    <svg
      fill="#b8b8b8"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      width="48px"
      height="48px"
      viewBox="0 0 101.38 101.379"
      stroke="#b8b8b8"
      x="16"
      y="12"
    >
      <path d="M32,5c-2.206,0-4,1.794-4,4s1.794,4,4,4s4-1.794,4-4S34.206,5,32,5z M32,11c-1.103,0-2-0.897-2-2s0.897-2,2-2s2,0.897,2,2 S33.103,11,32,11z"></path>
      <path d="M60,41h-5.63L46,33.826V41h-4c-1.654,0-3,1.346-3,3v8c0,1.654,1.346,3,3,3h18c1.654,0,3-1.346,3-3v-8 C63,42.346,61.654,41,60,41z M61,52c0,0.552-0.448,1-1,1H42c-0.552,0-1-0.448-1-1v-8c0-0.552,0.448-1,1-1h6v-4.826L53.63,43H60 c0.552,0,1,0.448,1,1V52z"></path>
      <path d="M44.256,1l-2.768,9.227c-0.164-0.094-0.317-0.201-0.488-0.285C40.969,5.006,36.943,1,32,1s-8.969,4.006-9,8.941 c-2.452,1.213-4.164,3.445-4.756,6.059H4c-1.654,0-3,1.346-3,3v8c0,1.654,1.346,3,3,3h5.63L18,37.174V30h4c1.654,0,3-1.346,3-3 v-0.232C25.644,26.915,26.312,27,27,27v14h-6v6h2v4h-2v6h6v2.414L30.586,63h2.828L37,59.414V27c4.963,0,9-4.037,9-9 c0-2.527-1.046-4.852-2.801-6.515L45.744,3H63V1H44.256z M23,27c0,0.552-0.448,1-1,1h-6v4.826L10.37,28H4c-0.552,0-1-0.448-1-1v-8 c0-0.552,0.448-1,1-1h14c0,1.438,0.347,2.793,0.95,4H18v2h2v-0.355c0.811,1.004,1.835,1.824,3,2.406V27z M23,55v-2h2v-8h-2v-2h4v12 H23z M37,25c-0.298,0-0.589-0.025-0.876-0.062L35,24.798v33.788L32.586,61h-1.172L29,58.586V24.798l-1.124,0.141 C27.589,24.975,27.298,25,27,25c-3.859,0-7-3.141-7-7c0-2.874,1.728-5.424,4.401-6.495l0.674-0.337l-0.053-0.729 C25.012,10.293,25,10.148,25,10c0-3.859,3.141-7,7-7s7,3.141,7,7c0,0.148-0.012,0.293-0.022,0.438l-0.057,0.795l0.678,0.271 C42.272,12.576,44,15.126,44,18C44,21.859,40.859,25,37,25z"></path>
      <rect height="2" width="15" x="48" y="5"></rect>
      <rect height="2" width="2" x="61" y="9"></rect>
      <rect height="2" width="11" x="48" y="9"></rect>
      <rect height="2" width="2" x="56" y="47"></rect>
      <rect height="2" width="2" x="50" y="47"></rect>
      <rect height="2" width="2" x="44" y="47"></rect>
      <rect height="2" width="2" x="12" y="22"></rect>
      <rect height="2" width="2" x="6" y="22"></rect>
    </svg>

    <svg
      x="10"
      y="10"
      width="6"
      height="6"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M84.8676 48.3201C85.1491 45.2265 82.6094 43.1217 80.1174 43.1235C65.6856 43.1338 56.2041 49.2543 50.4856 57.1878C44.9403 64.8812 43.1313 73.9718 43.1234 80.1136C43.1191 83.5225 46.2576 85.2601 48.7445 84.8068C64.3981 81.9535 73.5038 75.8461 78.658 68.3954C83.6947 61.1144 84.4196 53.2461 84.8676 48.3201ZM58.1696 62.7264C61.4622 58.1584 66.6031 54.1944 74.7548 52.9784C74.1614 56.3371 73.0993 59.7814 70.8682 63.0067C68.0526 67.0769 62.9909 71.3025 53.2801 74.0564C54.0672 70.3732 55.5941 66.2996 58.1696 62.7264Z"
          fill="#4552e8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.8325 0.0640259C50.4482 0.0640259 52.5686 2.1844 52.5686 4.80002V16.2113H75.3911V4.80002C75.3911 2.1844 77.5115 0.0640259 80.1271 0.0640259C82.7427 0.0640259 84.8631 2.1844 84.8631 4.80002V16.2113H106.353C109.296 16.2113 111.681 18.5967 111.681 21.5393V43.0966H123.2C125.816 43.0966 127.936 45.217 127.936 47.8326C127.936 50.4482 125.816 52.5686 123.2 52.5686H111.681V70.0087H123.2C125.816 70.0087 127.936 72.1291 127.936 74.7447C127.936 77.3604 125.816 79.4807 123.2 79.4807H111.681V106.353C111.681 109.296 109.296 111.681 106.353 111.681H84.8631V123.2C84.8631 125.816 82.7427 127.936 80.1271 127.936C77.5115 127.936 75.3911 125.816 75.3911 123.2V111.681H52.5686V123.2C52.5686 125.816 50.4482 127.936 47.8325 127.936C45.2169 127.936 43.0965 125.816 43.0965 123.2V111.681H21.5393C18.5968 111.681 16.2113 109.296 16.2113 106.353V79.4807H4.79996C2.18434 79.4807 0.0639648 77.3604 0.0639648 74.7447C0.0639648 72.1291 2.18434 70.0087 4.79996 70.0087H16.2113V52.5686H4.79996C2.18434 52.5686 0.0639648 50.4482 0.0639648 47.8326C0.0639648 45.217 2.18434 43.0966 4.79996 43.0966H16.2113V21.5393C16.2113 18.5968 18.5967 16.2113 21.5393 16.2113H43.0965V4.80002C43.0965 2.1844 45.2169 0.0640259 47.8325 0.0640259ZM25.6833 102.209V25.6833H102.209V102.209H25.6833Z"
          fill="#4552e8"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="128" height="128" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
)
