import React from 'react'
import { IconsColors } from '../../components/IconComponent/IconComponent'

const SvgComponent = ({ variable }) => {
  return (
    <>
      <svg width="12" height="7" viewBox="0 0 12 7" fill="none">
        <path
          d="M4.96437 1H3.31928C2.70488 1 2.11023 1.25474 1.67417 1.72185C1.2381 2.18895 0.990353 2.81525 1.00029 3.48407C0.990326 4.15287 1.23817 4.77925 1.67417 5.24629C2.12011 5.72397 2.69491 5.97878 3.30933 5.97879H4.95443M7.03563 6H8.68072C9.29512 6 9.88977 5.74526 10.3258 5.27815C10.7619 4.81105 11.0096 4.18475 10.9997 3.51593C10.9997 2.8578 10.752 2.23148 10.3159 1.76437C9.87982 1.29726 9.29515 1.03188 8.68072 1.03187H7.03563M3.52731 3.47678L8.4626 3.47678"
          stroke={IconsColors[variable]}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
}
export default SvgComponent
