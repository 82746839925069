import { useQuery } from '@tanstack/react-query'

import dashboardOverviewApi from '../'

export const GET_ONE_TABLE_DASHBOARD_QUERY_KEY =
  'DashboardOverview_GetOneUserDashboardOverview'

export const getOneDashboardOverview = async (
  id: string | null,
  shareable?: string,
  pdf?: string,
  dashboard?: any
) => {
  if (!id) return null

  if (shareable || pdf) {
    const param = shareable ? `shareable=${shareable}` : `pdf=${pdf}`
    return dashboardOverviewApi
      .get(`/shareable/${id}?${param}&dashboard=${dashboard.id}`)
      .then(response => response.data)
  }

  return dashboardOverviewApi.get(`/${id}`).then(response => response.data)
}

const useGetOneDashboardOverview = (
  id: string | null,
  shareable?: string,
  pdf?: string,
  dashboard?: any
) => {
  return useQuery(
    [GET_ONE_TABLE_DASHBOARD_QUERY_KEY, id],
    () => getOneDashboardOverview(id, shareable, pdf, dashboard),
    {
      staleTime: 1000 * 60 * 60, // 1 hour
    }
  )
}

export default useGetOneDashboardOverview
