import React from 'react'
import { BackgroundIconsColors } from '../../components/IconComponent/IconComponent'

const SvgComponent = ({ variable }) => {
  return (
    <>
      <svg width="23" height="23" viewBox="0 0 23 23" fill="none">
        <rect
          width="23"
          height="23"
          rx="3"
          fill={BackgroundIconsColors[variable].background}
        />
        <path
          d="M8 15.6914C8.20235 15.889 8.4768 16 8.76297 16H15.237C15.5232 16 15.7976 15.889 16 15.6914M12.0006 7V12.9712M12.0006 12.9712L14.4669 10.6896M12.0006 12.9712L9.5343 10.6896"
          stroke={BackgroundIconsColors[variable].color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
}
export default SvgComponent
