import React from 'react'
import { IconsColors } from '../../components/IconComponent/IconComponent'

const SvgComponent = ({ variable, width = '14', height = '14' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Register_UI"
          transform="translate(-305.000000, -649.000000)"
          stroke={IconsColors[variable]}
          strokeWidth="2"
        >
          <g id="Side" transform="translate(261.000000, 57.000000)">
            <g id="logo" transform="translate(0.000000, 558.000000)">
              <g transform="translate(45.000000, 27.000000)" id="cpu">
                <g transform="translate(0.000000, 8.000000)">
                  <rect
                    id="Rectangle"
                    x="3"
                    y="3"
                    width="16"
                    height="16"
                    rx="2"
                  ></rect>
                  <path
                    d="M14,8 L14,8 L14,8 C14,11.3137085 11.3137085,14 8,14 L8,14 L8,14 C8,10.6862915 10.6862915,8 14,8 Z"
                    id="Rectangle"
                  ></path>
                  <path d="M8,0 L8,3" id="Path"></path>
                  <path d="M14,0 L14,3" id="Path"></path>
                  <path d="M8,19 L8,22" id="Path"></path>
                  <path d="M14,19 L14,22" id="Path"></path>
                  <path d="M19,8 L22,8" id="Path"></path>
                  <path d="M19,13 L22,13" id="Path"></path>
                  <path d="M0,8 L3,8" id="Path"></path>
                  <path d="M0,13 L3,13" id="Path"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
export default SvgComponent
