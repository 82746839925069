// @ts-nocheck
import React, { useEffect, useRef } from 'react'
import { Transition } from 'react-transition-group'

import DeleteIcon from '../../assets/icons/delete-icon.svg'
import EditIcon from '../../assets/icons/pen.svg'
import DuplicateIcon from '../../assets/icons/duplicate-icon.svg'

const defaultStyle = {
  transition: 'opacity 150ms ease-in-out',
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}

const items = [
  {
    name: 'Delete',
    img: DeleteIcon,
    hideWhileLoading: true,
  },
  {
    name: 'Edit',
    img: EditIcon,
    hideWhileLoading: true,
  },
  {
    name: 'Duplicate',
    img: DuplicateIcon,
  },
]

const Tooltip = ({ show, toggle, onDelete, onEdit, onDuplicate }) => {
  const ref = useRef(null)

  useEffect(() => {
    const handleClickOutside = e => {
      if (ref.current && !ref.current.contains(e.target)) {
        toggle()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [toggle])

  const handleClick = buttonName => {
    try {
      const handler = {
        Delete: onDelete,
        Edit: onEdit,
        Duplicate: onDuplicate,
      }[buttonName]
      handler && handler()
      if (!buttonName.includes('Move')) toggle()
    } catch (e) {
      console.log(e)
    }
  }

  const commonStyles = { width: '100px' }

  return (
    <Transition in={show} timeout={300} mountOnEnter unmountOnExit>
      {state => (
        <div
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
            ...commonStyles,
          }}
          ref={ref}
          className="table-popup table-popup-action"
          onMouseDown={e => e.stopPropagation()}
          onMouseMove={e => e.stopPropagation()}
        >
          <ul style={commonStyles}>
            {items.map(item => (
              <li key={item.name} style={{ padding: '5px 5px' }}>
                <button
                  onClick={() => handleClick(item.name)}
                  className={`button-container ${
                    item.name === 'Delete' ? 'hide-on-mobile' : ''
                  }`}
                  type="button"
                >
                  <img
                    src={item.img}
                    className={`${item.name}-button preload-animation`}
                    alt=""
                  />
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </Transition>
  )
}

export default Tooltip
