import React from 'react'
import { BackgroundIconsColors } from '../../components/IconComponent/IconComponent'

const SvgComponent = ({ variable }) => {
  return (
    <>
      <svg width="23" height="23" viewBox="0 0 23 23" fill="none">
        <rect
          width="23"
          height="23"
          rx="3"
          fill={BackgroundIconsColors[variable].background}
        />
        <path
          d="M10.9644 9H9.31928C8.70488 9 8.11023 9.25474 7.67417 9.72185C7.2381 10.189 6.99035 10.8153 7.00029 11.4841C6.99033 12.1529 7.23817 12.7793 7.67417 13.2463C8.12011 13.724 8.69491 13.9788 9.30933 13.9788H10.9544M13.0356 14H14.6807C15.2951 14 15.8898 13.7453 16.3258 13.2782C16.7619 12.811 17.0096 12.1847 16.9997 11.5159C16.9997 10.8578 16.752 10.2315 16.3159 9.76437C15.8798 9.29726 15.2951 9.03188 14.6807 9.03187H13.0356M9.52731 11.4768L14.4626 11.4768"
          stroke={BackgroundIconsColors[variable].color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
}
export default SvgComponent
