import './App.css'
import DashboardContainer from './components/DashboardContainer'
import { QueryClientProvider } from '@tanstack/react-query'
import defaultQueryClient from '@mm/api/src/reactQuery'
import { IntlProvider } from 'react-intl'
import AppLocale from '@mm/ui/src/lang'

const navigatorLanguage = (
  (window.navigator as any).userLanguage ||
  window.navigator.language ||
  ''
).split('-')[0]
const navigatorLanguageAvailable: 'es' | 'en' | 'pt' | false = ([
  'es',
  'en',
  'pt',
].includes(navigatorLanguage) && navigatorLanguage) as
  | 'es'
  | 'en'
  | 'pt'
  | false

const currentAppLocale = AppLocale[navigatorLanguageAvailable || 'en']

function App() {
  return (
    <div className="App">
      <IntlProvider locale={'en'} messages={currentAppLocale}>
        <QueryClientProvider client={defaultQueryClient}>
          <DashboardContainer />
        </QueryClientProvider>{' '}
      </IntlProvider>
      ,
    </div>
  )
}

export default App
