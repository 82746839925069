import React from 'react'
import WarningAlert from './WarningAlert'
import ReloadSourceAlert from './ReloadSourceAlert'

export interface SmartTableError {
  messages: string[]
  errorsData: SourceError[]
}

export interface SourceError {
  code: string
  status: number
  source: string
  adAccountId: string
  adAccount: AdAccount
  errorData: ErrorData
  message: string
}

interface ErrorData {
  source: string
  sourceName: string
  adAccountId: string
  adAccount: AdAccount
}

interface AdAccount {
  id: string
  name: string
  status: string
  currency: string
  createdAt: string
  advertiser_account_type: string
}
interface SmartTableColumnProps {
  id: string
  error: SmartTableError
}

const SourceErrors = ['MISSING_PERMISSION', 'ACCESS_TOKEN_HAS_EXPIRED']

export const SmartTableErrorCell = ({ id, error }: SmartTableColumnProps) => {
  const sourceError = error?.errorsData?.find(error =>
    SourceErrors.includes(error.code)
  )
  if (sourceError) {
    return <ReloadSourceAlert error={sourceError} id={id} />
  }

  return <WarningAlert errors={error} id={id} />
}
