/* eslint-disable import/no-extraneous-dependencies */
import React, { Component } from 'react'
import {
  Button,
  Card,
  CardBody,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { DateRanges, getDatesArray } from '@mm/utils/src/constants/ranges'
import { AdvanceLastDaysSelect } from '../AdvanceLastDaysSelect/AdvanceLastDaysSelect'
import { calculateLastXDayPeriod } from '../DateSelectCompareCard/utils'
import Checkbox from '../CustomCheckbox'
import { IncludeTodayLabel } from './styles'
const datesArray = getDatesArray()

export const toYesterdayOptions = [
  DateRanges.ThisWeek,
  DateRanges.ThisMonth,
  DateRanges.ThisQuarter,
  DateRanges.ThisYear,
]

export const toYesterdayNames = [
  'This week',
  'This month',
  'This quarter',
  'This year',
]

export const requireTwoCalendar = [
  DateRanges.ThisQuarter,
  DateRanges.ThisYear,
  DateRanges.LastQuarter,
  DateRanges.LastYear,
]

class DateSelectCard extends Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      dropdownOpen: false,
      startDate: null,
      endDate: null,
      hasChanged: false,
      previousStartDate: null,
      previousEndDate: null,
      previousSelectedDate: null,
      lastDays: null,
      untilToday: false,
      previousUntilToday: false,
    }
  }

  componentDidUpdate = () => {
    const { dateChosen, handleDateChange } = this.props
    if (
      !!dateChosen &&
      dateChosen?.id !== undefined &&
      dateChosen?.id !== this.state?.selectedDate
    ) {
      this.handleDateChosen(dateChosen)
      handleDateChange(
        dateChosen.from,
        dateChosen.to,
        dateChosen.id,
        dateChosen.lastDays,
        dateChosen.untilToday
      )
    }
  }

  componentDidMount = () => {
    const { dateChosen } = this.props
    this.handleDateChosen(dateChosen)
  }

  handleDateChosen = dateChosen => {
    if (dateChosen) {
      if (dateChosen.dateFilterFrom)
        this.setState({ startDate: moment(dateChosen.dateFilterFrom) })
      if (dateChosen.dateFilterTo)
        this.setState({ endDate: moment(dateChosen.dateFilterTo) })
      if (dateChosen.dateFilterRange) {
        const date = datesArray.find(
          item => item.name === dateChosen.dateFilterRange
        )
        const untilToday =
          dateChosen.dateFilterUntilToday === null ||
          dateChosen.dateFilterUntilToday === undefined
            ? date.untilTodayDefault
            : !!dateChosen.dateFilterUntilToday

        if (untilToday === false && toYesterdayOptions.includes(date.id)) {
          this.setState({
            selectedDate: date.id,
            lastDays: dateChosen.dateFilterLastDays,
            untilToday: untilToday,
            startDate: this.findDate(date.id, 'from'),
            endDate: this.findDate(date.id, 'to', untilToday),
          })
          return
        }

        if (date.id === DateRanges.LastDays && dateChosen.dateFilterLastDays) {
          const lastDaysRange = calculateLastXDayPeriod(
            dateChosen.dateFilterLastDays,
            dateChosen.dateFilterUntilToday
          )
          this.setState({
            selectedDate: date.id,
            lastDays: dateChosen.dateFilterLastDays,
            untilToday: untilToday,
            startDate: moment(lastDaysRange.startDate),
            endDate: moment(lastDaysRange.endDate),
          })
          return
        }

        if (dateChosen.dateFilterFrom && dateChosen.dateFilterTo) {
          this.setState({ selectedDate: date.id, untilToday: untilToday })
          return
        }

        this.setState({
          selectedDate: date.id,
          startDate: moment(date.from),
          endDate: this.findDate(
            date.id,
            'to',
            dateChosen.dateFilterUntilToday
          ),
          lastDays: dateChosen.dateFilterLastDays,
          untilToday: untilToday,
        })
      }
    }
  }

  findDate(id, type, untilToday) {
    if (id && id !== DateRanges.Customize && id !== DateRanges.LastDays) {
      const date = datesArray.find(item => item.id === id)
      switch (type) {
        case 'from':
          return moment(date.from)
        case 'to':
          if (!untilToday && toYesterdayOptions.includes(id))
            return moment().subtract(1, 'days')
          return moment(date.to)
        default:
          break
      }
    }

    if (id === DateRanges.LastDays) {
      switch (type) {
        case 'from':
          if (this.state.untilToday)
            return moment().subtract(Number(this.state.lastDays) + 1, 'day')
          return moment().subtract(Number(this.state.lastDays), 'day')
        case 'to':
          if (this.state.untilToday) return moment()
          return moment().subtract(1, 'day')
        default:
          break
      }
    }

    return undefined
  }

  toggle() {
    const { startDate, endDate, selectedDate } = this.state
    const { styles, selectedDateId, onRemoveDateChosen, isTableSelector } =
      this.props
    if (styles) {
      this.setState(prevState => ({
        dropdownOpen: !prevState.dropdownOpen,
        selectedDate:
          selectedDate === null || selectedDate === undefined
            ? 1
            : selectedDate,
        startDate:
          startDate === null || startDate === undefined ? moment() : startDate,
        endDate:
          endDate === null || endDate === undefined
            ? moment()
            : selectedDate === DateRanges.Customize
              ? endDate
              : this.findDate(selectedDate, 'to', prevState.untilToday),
        untilToday: prevState.untilToday,
      }))
    } else {
      this.setState(prevState => {
        if (prevState.dropdownOpen && onRemoveDateChosen && isTableSelector) {
          onRemoveDateChosen()
        }
        const customStartDate =
          selectedDateId === DateRanges.Customize ? startDate : undefined
        const customEndDate =
          selectedDateId === DateRanges.Customize ? endDate : undefined
        const calculatedDate = prevState.dropdownOpen
          ? selectedDateId
          : selectedDate === null || selectedDate === undefined
            ? 1
            : selectedDate
        const calculatedStartDate = prevState.dropdownOpen
          ? this.findDate(selectedDateId, 'from') || customStartDate
          : startDate === null || startDate === undefined
            ? moment()
            : startDate
        const calculatedEndDate = prevState.dropdownOpen
          ? this.findDate(
              selectedDateId,
              'to',
              this.props.dateChosen?.dateFilterUntilToday
            ) || customEndDate
          : endDate === null || endDate === undefined
            ? moment()
            : endDate

        return {
          dropdownOpen: !prevState.dropdownOpen,
          selectedDate: calculatedDate,
          previousSelectedDate: calculatedDate,
          startDate: calculatedStartDate,
          previousStartDate: calculatedStartDate,
          endDate: calculatedEndDate,
          previousEndDate: calculatedEndDate,
          untilToday: this.props.dateChosen?.dateFilterUntilToday,
        }
      })
    }
  }

  handleCustomDate = date => {
    const { startDate, endDate } = this.state
    if (endDate && endDate !== null) {
      this.setState({ startDate: date, endDate: null })
      return
    }
    if (startDate) {
      if (startDate > date) {
        const startAux = startDate
        this.setState({ startDate: date, endDate: startAux })
      } else this.setState({ endDate: date })
      return
    }
  }

  handleLastDayDate = newLastDaysState => {
    if (newLastDaysState) {
      this.setState(newLastDaysState)
      return
    }
  }

  onChangeUntilToday = e => {
    if (e) {
      const untilToday = e.target.checked
      this.setState({
        untilToday: untilToday,
        endDate: this.findDate(this.state.selectedDate, 'to', untilToday),
      })
      return
    }
  }

  handleSelectChange = optionId => {
    const date = datesArray.find(item => item.id === optionId)
    if (optionId !== DateRanges.Customize)
      this.setState({
        startDate: moment(date.from),
        endDate: moment(date.to),
        untilToday: date.untilTodayDefault,
      })
    else
      this.setState({
        startDate: null,
        endDate: moment(),
        untilToday: date.untilTodayDefault,
      })
    this.setState({ collapsed: true, selectedDate: optionId, hasChanged: true })
  }

  handleCancel = () => {
    const { styles, handleDateChange } = this.props
    this.setState({
      selectedDate: undefined,
      startDate: null,
      endDate: null,
      lastDays: null,
      untilToday: null,
    })
    if (styles) handleDateChange(null, null, undefined, null, null, false)
  }

  render() {
    const {
      error,
      styles,
      dateChosen,
      style,
      handleDateChange,
      isTableSelector,
      onRemoveDateChosen,
      isFromForm,
      handleClearDate,
      enableLastDays,
      origin,
    } = this.props

    const {
      selectedDate,
      startDate,
      endDate,
      dropdownOpen,
      hasChanged,
      previousEndDate,
      previousSelectedDate,
      previousStartDate,
      lastDays,
      untilToday,
      previousUntilToday,
    } = this.state
    const regexNumber = /^[1-9]\d*$/
    const disabledApply =
      !startDate ||
      !endDate ||
      (isTableSelector && !hasChanged) ||
      (selectedDate === DateRanges.LastDays &&
        (!lastDays || !regexNumber.test(lastDays) || Number(lastDays) > 720))

    // TODO: Quitar esta condicion una vez se pueda habilitar para todos los casos
    const datesOptions = enableLastDays
      ? datesArray
      : datesArray.filter(d => d.name !== 'Last days')

    const rangeDateText =
      (startDate === null || startDate === undefined) &&
      (endDate === null || endDate === undefined) &&
      (!dateChosen ||
        ((dateChosen.dateFilterFrom === null ||
          dateChosen.dateFilterFrom === undefined) &&
          (dateChosen.dateFilterTo === null ||
            dateChosen.dateFilterTo === undefined)))
        ? 'Choose date'
        : moment(startDate || moment()).format('MMMM Do, YYYY') +
          ' - ' +
          moment(endDate || moment()).format('MMMM Do, YYYY')

    const showTwoCalendar =
      (selectedDate && requireTwoCalendar.includes(selectedDate)) ||
      (selectedDate !== DateRanges.ThisWeek &&
        startDate &&
        endDate &&
        selectedDate !== DateRanges.Customize &&
        (startDate.month() !== endDate.month() ||
          startDate.year() !== endDate.year()))

    return (
      <>
        <div className="main-view-container">
          <div
            className="buttons-group dashboard-button-container"
            style={style}
          >
            <Dropdown
              isOpen={dropdownOpen}
              toggle={this.toggle}
              disabled={dateChosen?.disabled}
              onBlur={() => {
                if (styles) {
                  handleDateChange(
                    startDate,
                    endDate,
                    selectedDate,
                    lastDays,
                    untilToday
                  )
                }
              }}
              className="dropdown-menu-right"
            >
              <DropdownToggle className="p-0" color="empty">
                <span
                  className={
                    'btn add-new-button custom-outline-btn btn-outline-primary option-btn ' +
                    styles
                  }
                  style={{
                    borderColor: dropdownOpen ? '#4552e8' : '#A5A6AF',
                    marginRight: 0,
                  }}
                >
                  {rangeDateText}
                  <i></i>
                </span>
              </DropdownToggle>
              <DropdownMenu
                className={`date-select-menu ${origin ? `date-menu-${origin}` : ''}`}
              >
                <div className="date-menu ">
                  <Card
                    style={{
                      width: showTwoCalendar ? '733px' : '450px',
                    }}
                  >
                    <CardBody>
                      <div className="date-select">
                        <ul>
                          <div>
                            <div>
                              {datesOptions.map(dateItem => (
                                <li
                                  onClick={() =>
                                    this.handleSelectChange(dateItem.id)
                                  }
                                  key={dateItem.id}
                                  className={
                                    selectedDate === dateItem.id
                                      ? 'selected'
                                      : ''
                                  }
                                >
                                  {dateItem.date || dateItem.name}
                                </li>
                              ))}
                            </div>
                          </div>
                        </ul>
                        {selectedDate &&
                        selectedDate === DateRanges.Customize ? (
                          <DatePicker
                            selected={endDate}
                            onChange={this.handleCustomDate}
                            startDate={startDate}
                            endDate={endDate || moment()}
                            selectsRange
                            inline
                          />
                        ) : enableLastDays &&
                          selectedDate === DateRanges.LastDays ? (
                          <AdvanceLastDaysSelect
                            onChange={this.handleLastDayDate}
                            dateChosen={dateChosen}
                          />
                        ) : (
                          <div>
                            <DatePicker
                              disabled
                              selected={startDate}
                              startDate={startDate}
                              endDate={endDate}
                              selectsRange
                              inline
                            />
                            {toYesterdayOptions.includes(selectedDate) ? (
                              <IncludeTodayLabel
                                style={{
                                  marginBottom: '0px',
                                  cursor: 'pointer',
                                }}
                              >
                                <Checkbox
                                  type="checkbox"
                                  checked={untilToday}
                                  onChange={this.onChangeUntilToday}
                                />
                                <span style={{ marginLeft: 2 }}>
                                  Includes Today
                                </span>
                              </IncludeTodayLabel>
                            ) : null}
                          </div>
                        )}
                        {requireTwoCalendar.includes(selectedDate) ? (
                          <DatePicker
                            disabled
                            selected={endDate}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            inline
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                      {styles ? (
                        <></>
                      ) : (
                        <div className="date-actions">
                          {handleClearDate && (
                            <Button
                              className={
                                'btn btn-outline-primary custom-outline-btn add-filter-link date-btn'
                              }
                              style={{ marginRight: '20px' }}
                              onClick={() => {
                                handleClearDate()
                                this.setState({
                                  selectedDate: null,
                                  dropdownOpen: false,
                                  startDate: null,
                                  endDate: null,
                                  hasChanged: false,
                                })
                              }}
                            >
                              Clear
                            </Button>
                          )}
                          <Button
                            className={
                              'btn btn-outline-primary custom-outline-btn add-filter-link date-btn'
                            }
                            disabled={disabledApply}
                            onClick={e => {
                              const { dateChosen } = this.props
                              if (
                                dateChosen &&
                                (dateChosen.dateFilterFrom ||
                                  dateChosen.dateFilterTo ||
                                  dateChosen.dateFilterRange ||
                                  dateChosen.dateFilterLastDays ||
                                  dateChosen.dateFilterUntilToday)
                              ) {
                                if (onRemoveDateChosen) onRemoveDateChosen(e)
                              }
                              if (handleClearDate) {
                                this.setState({
                                  selectedDate: previousSelectedDate,
                                  dropdownOpen: false,
                                  startDate: previousStartDate,
                                  endDate: previousEndDate,
                                  untilToday: previousUntilToday,
                                  hasChanged: false,
                                })
                              } else {
                                this.setState({ dropdownOpen: false })
                              }
                            }}
                          >
                            {dateChosen &&
                            (dateChosen.dateFilterFrom ||
                              dateChosen.dateFilterTo ||
                              dateChosen.dateFilterRange) &&
                            !isFromForm
                              ? isTableSelector
                                ? 'Cancel'
                                : 'Undo Filter'
                              : 'Cancel'}
                          </Button>
                          <Button
                            className={
                              'btn btn-primary custom-btn add-filter-link date-btn'
                            }
                            disabled={disabledApply}
                            onClick={() => {
                              handleDateChange(
                                startDate,
                                endDate,
                                selectedDate,
                                lastDays,
                                untilToday
                              )
                              this.setState({ dropdownOpen: false })
                            }}
                          >
                            Apply
                          </Button>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </div>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        {error && (
          <div
            className="error-message"
            style={{
              opacity: error ? '1' : '0',
              color: '#fc6363',
              fontSize: '12px',
            }}
          >
            <span>{error}</span>
          </div>
        )}
      </>
    )
  }
}

export default DateSelectCard
