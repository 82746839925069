//@ts-nocheck
import useGetPowertileData from '@mm/api/src/powertiles/requests/useGetPowertilesData'
import React from 'react'
import Loader from '../Loader'
import { formatMetric } from '../Powertable/utils'
import ResizableText from './ResizableText'
import TooltipMenu from './TooltipMenu'
import WidgetIcon from '../WidgetIcon'
import WidgetErrorMessage from '../WidgetErrorMessage/WidgetErrorMessage'

const PowerTile = ({
  dateChosen,
  onDelete,
  onEdit,
  onDuplicate,
  onCompare,
  handleToggleTooltip,
  hideActions,
  showTooltip,
  shareable,
  widget,
  pdf,
  dashboard,
}) => {
  const {
    data: response,
    isLoading,
    error,
  } = useGetPowertileData({
    id: widget.id,
    shareable: shareable,
    pdf,
    dateChosen,
    dashboard,
  })

  const compareParams =
    error || isLoading
      ? {}
      : {
          onCompare: () =>
            onCompare(response.data.previousPeriod === undefined),
          isCompared: response.data?.previousPeriod !== undefined,
        }

  return (
    <>
      {error ? (
        <ResizableText
          error={error.response.data.message}
          errorComponent={
            <WidgetErrorMessage
              error={error.response.data}
              widgetId={widget.id}
              isPublic={!!shareable || !!pdf}
              onEdit={dateChosen?.dateFilterRange ? undefined : onEdit}
            />
          }
          altMessageColor={'red'}
          hideActions={hideActions}
          handleToggleTooltip={handleToggleTooltip}
        />
      ) : isLoading || !response ? (
        <Loader style={{ margin: 'auto' }} />
      ) : (
        <>
          {response.show_icons && <WidgetIcon sources={[response.source]} />}
          <ResizableText
            formatedValue={formatMetric(
              response.metric || '',
              response.data.current,
              response.decimals
            )}
            currentValue={response.data.current}
            previousDataString={response.data.previousDataString}
            widgetId={widget.id}
            previousPeriod={response.data?.previousPeriod}
            formatedPreviousPeriod={formatMetric(
              response.metric,
              response.data?.previousPeriod,
              response.decimals
            )}
            metric={
              response.name
                ? { name: response.name, type: response.metric }
                : {
                    name: response.label[response.metric],
                    type: response.metric,
                  }
            }
            hideActions={hideActions}
            dateString={response.data.dateString ?? response.range}
            altMessageColor={''}
            handleToggleTooltip={handleToggleTooltip}
          />
        </>
      )}
      {hideActions && isLoading === false && (
        <TooltipMenu
          show={showTooltip}
          toggle={handleToggleTooltip}
          onDelete={onDelete}
          onEdit={onEdit}
          onDuplicate={onDuplicate}
          {...compareParams}
        />
      )}
    </>
  )
}

export default PowerTile
