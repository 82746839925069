import React from 'react'
import { IconsColors } from '../../components/IconComponent/IconComponent'

const SvgComponent = ({ variable }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 1.33325V5.33325H6"
        stroke={IconsColors[variable]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 7.99991C13.9989 6.84212 13.6629 5.70936 13.0325 4.73827C12.4021 3.76717 11.5041 2.99922 10.447 2.52703C9.38987 2.05485 8.2187 1.8986 7.07476 2.07714C5.93082 2.25568 4.86297 2.76138 4 3.53324L2 5.33324"
        stroke={IconsColors[variable]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 14.6667V10.6667H10"
        stroke={IconsColors[variable]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 8C2.00105 9.15779 2.33707 10.2905 2.9675 11.2616C3.59794 12.2327 4.49588 13.0007 5.55301 13.4729C6.61013 13.9451 7.7813 14.1013 8.92524 13.9228C10.0692 13.7442 11.137 13.2385 12 12.4667L14 10.6667"
        stroke={IconsColors[variable]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default SvgComponent
