import React from 'react'

export const ReconnectIconSvg = ({ stroke }: { stroke?: string }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 11a8.1 8.1 0 0 0-15.5-2M4 5v4h4"
      fill="none"
      stroke={stroke ?? '#fff'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 13a8.1 8.1 0 0 0 15.5 2M20 19v-4h-4"
      fill="none"
      stroke={stroke ?? '#fff'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
